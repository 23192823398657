// Dependências
import axios from 'axios';
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

// Actions
import { setPerfil } from "./store/actions/perfil";
import { setCoordenadas } from "./store/actions/coordenadas";
import { removeToken } from "./store/actions/auth";

// Components
import Delayed from "./components/UI/Delayed/Delayed";
import Footer from "./components/Footer/Footer";
import Navbar from "./components/Navbar/Navbar";
import PermissionCard from "./components/PermissionCard/PermissionCard";
import Screens from './screens/index';
import SideDrawer from "./components/Navbar/SideDrawer/SideDrawer";

// Css
import classes from "./App.module.css";

// Helpers
import { error as alertError } from "./helpers/sweetAlert";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const App = () => {
    const history = useHistory();

    const token = useSelector(state => state.auth.token);
    const hasPerfil = useSelector(state => state.perfil.hasPerfil);

    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);
    const [isPerfilRequestDone, setIsPerfilRequestDone] = useState(false);
    const [showCardAskingPermission, setShowCardAskingPermission] = useState(false);
    const [showSideDrawer, setShowSideDrawer] = useState(false);

    // Pede permissão e salva a latitude e longitude do usuário
    const getCoords = useCallback(() => {
        setShowCardAskingPermission(false);

        if ("geolocation" in navigator) {
            navigator.geolocation?.getCurrentPosition?.(position => {
                dispatch(setCoordenadas({ latitude: position.coords.latitude, longitude: position.coords.longitude }));
            });
        };
    }, [dispatch]);

    // Se tiver token, faz requisição do perfil
    useEffect(() => {
        if (hasPerfil === undefined && token && !isLoading && !isPerfilRequestDone) {
            const fetchPerfil = async () => {
                setIsLoading(true);
                setIsPerfilRequestDone(true);

                axios({
                    method: "GET",
                    url: `${BASE_URL}/servicos/perfil?hasPerfil=true`,
                    headers: {
                        'Content-Type': `application/json; charset=utf-8`,
                        'authorization': token
                    },
                }).then(result => {
                    if (result.status === 200) {
                        dispatch(setPerfil(result.data))
                        if (!result.data.id) history.push("/home");
                    } else dispatch(removeToken());
                }).catch(error => {
                    if (error.message === "Network Error") alertError({ title: "Erro de Conexão", text: "Por favor, verifique sua conexão com a internet." });
                    console.error(error);
                    alertError({
                        title: `${error.response?.status ?? ""} ${error.response?.statusText ?? ""}`,
                        text: error.response?.data?.message || "Alguma coisa deu errado, tente novamente. Se o problema persistir, por favor contate-nos",
                        footer: error.response?.data?.cause ? `<strong>Cause:</strong> ${error.response.data.cause}` : null
                    });
                });

                setIsLoading(false);
            };

            fetchPerfil();
        };
    }, [dispatch, isLoading, isPerfilRequestDone, hasPerfil, token, history]);

    // Seta as coordenadas do usuário
    useEffect(() => {
        if ("permissions" in navigator) {
            navigator?.permissions?.query({ name: 'geolocation' }).then(result => {
                if (!result) return;

                // Se a permissão de geolocation foi aceita
                if (result.state === 'granted') getCoords();

                // Se a permissão de geolocation não foi perguntada
                else if (result.state === 'prompt') setShowCardAskingPermission(true);

                // Se a permissão de geolocation foi negada nada acontece
            });
        } else getCoords();
    }, [getCoords]);

    return (
        <>
            <Navbar showSideDrawer={showSideDrawer} onHamburgerButtonClick={() => setShowSideDrawer(prevSideDrawer => !prevSideDrawer)} />
            <SideDrawer display={showSideDrawer} onClose={() => setShowSideDrawer(false)} />


            <div className={classes.Screens}>
                <Screens />
            </div>

            {showCardAskingPermission ? (
                <Delayed delay={3000}>
                    <PermissionCard onAccept={getCoords} onReject={() => setShowCardAskingPermission(false)} />
                </Delayed>
            ) : null}

            <Footer />
        </>
    );
};

export default App;