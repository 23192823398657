// Dependências
import React from "react";

// Css
import classes from "./Toggle.module.css";


const Toggle = props => {
    return (
        <label className={classes.Switch}>
            <input
                id={props.id}
                type="checkbox"
                defaultChecked={props.defaultChecked}
                onChange={props.onChange}
            />
            <span className={classes.Slider} />
        </label>
    );
};

export default Toggle;