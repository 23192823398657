// Dependências
import React from "react";

// Components
import SearchBar from "../SearchBar/SearchBar";

// Css
import classes from "./Banner.module.css";


const Banner = () => {
    return (

        <section className={classes.Banner} style={{ backgroundImage: `url("/images/banner.jpg")` }}>
            <div className={classes.Content}>
                <img className={classes.Logo} src="/images/logo-branca.png" alt="Portal Vegano" />

                <h1 className={classes.Title}>Sua saúde, bem-estar e autocuidado.</h1>

                <div className={classes.SearchBar}>
                    <SearchBar />
                </div>

                <div className={classes.Subtitle}>Encontre os profissionais especializados que você precisa!</div>
            </div>
        </section>
    );
};

export default Banner;