// Dependências
import React from "react";

// Css
import classes from "./DiaSemana.module.css";


const DiaSemana = ({ dia, value, onChange }) => {
    return (
        <div className={classes.Day}>
            <label
                className={`${classes.Label} ${value ? classes.Ativo : ""}`}
                htmlFor={dia}
                role="button"
                tabIndex={0}
            >{dia.substr(0, 3).toUpperCase()}</label>

            <input
                checked={value}
                id={dia}
                onChange={onChange}
                type="checkbox"
                style={{ display: 'none' }}
            />
        </div>
    );
};

export default DiaSemana;