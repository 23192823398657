// Dependências
import React, { useEffect, useState } from 'react';
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { RiErrorWarningLine } from "react-icons/ri";
import { useDispatch, useSelector } from 'react-redux';

// Actions
import { setCadastro } from "../../../store/actions/cadastro";

// Css
import classes from "./Cadastro2Screen.module.css";

// Components
import Button from "../../../components/UI/Button/Button";
import Input from "../../../components/UI/Input/Input";

// Helpers
import * as validator from "../../../helpers/validator";


const Cadastro2Screen = ({ history }) => {
    const dispatch = useDispatch();
    const cadastro = useSelector(state => state.cadastro);

    const [isValid, setIsValid] = useState({
        celular: true,
        celular2: true,
        telefone: true,
        email: true,
        facebook: true,
        instagram: true,
        site: true
    });

    // Valida se os passos do cadastro anterior foram cumpridos
    useEffect(() => {
        if (!cadastro.nome || !cadastro.categoria) history.push("/cadastro/1");
    }, [cadastro, history]);

    const submitHandler = event => {
        event.preventDefault();
        const copiaIsValid = { ...isValid };

        copiaIsValid.facebook = cadastro.facebook ? !validator.link(cadastro.facebook) : true;
        copiaIsValid.instagram = cadastro.instagram ? !validator.link(cadastro.instagram) : true;

        copiaIsValid.email = cadastro.email ? validator.email(cadastro.email) : true;
        copiaIsValid.celular = cadastro.celular ? validator.telefone(cadastro.celular) : true;
        copiaIsValid.celular2 = cadastro.celular2 ? validator.telefone(cadastro.celular2) : true;
        copiaIsValid.telefone = cadastro.telefone ? validator.telefone(cadastro.telefone) : true;

        setIsValid({ ...copiaIsValid });

        // Se não existir nenhum valor inválido
        if (!Object.values(copiaIsValid).includes(false)) history.push("/cadastro/3");
    };


    return (
        <div style={{ padding: "0 .5rem" }}>
            <h1>Cadastre seu Serviço</h1>

            <form onSubmit={submitHandler}>
                <fieldset className={classes.Fieldset}>
                    <legend>Informações de contato</legend>

                    <div style={{ textAlign: "right", marginBottom: ".5rem" }}>
                        <RiErrorWarningLine style={{ color: "tomato" }} /> Estas informações serão disponibilizadas publicamente.
                    </div>

                    <div className={classes.Group}>
                        <Input
                            defaultValue={cadastro.celular}
                            invalid={!isValid.celular}
                            label="Celular:"
                            mask="(99) 99999-9999"
                            name="telefone"
                            onChange={event => dispatch(setCadastro("celular", event.target.value.replace(/\D/g, "")))}
                            placeholder="(99) 99999-9999"
                        />

                        <Input
                            defaultValue={cadastro.celular2}
                            invalid={!isValid.celular2}
                            label="Celular 2:"
                            mask="(99) 99999-9999"
                            name="telefone"
                            onChange={event => dispatch(setCadastro("celular2", event.target.value.replace(/\D/g, "")))}
                            placeholder="(99) 99999-9999"
                        />

                        <Input
                            defaultValue={cadastro.telefone}
                            invalid={!isValid.telefone}
                            label="Telefone fixo:"
                            mask="(99) 9999-9999"
                            name="fixo"
                            onChange={event => dispatch(setCadastro("telefone", event.target.value.replace(/\D/g, "")))}
                            placeholder="(99) 9999-9999"
                        />
                    </div>

                    <Input
                        defaultValue={cadastro.email}
                        invalid={!isValid.email}
                        label="Email para contato:"
                        name="email"
                        onChange={event => dispatch(setCadastro("email", event.target.value))}
                        placeholder="Ex: contato.portalvegano@gmail.com"
                    />

                    <div className={classes.Group}>
                        <Input
                            defaultValue={cadastro.instagram}
                            invalid={!isValid.instagram}
                            label={<span><FaInstagram /> Instagram:</span>}
                            maxLength="160"
                            name="instagram"
                            onChange={event => dispatch(setCadastro("instagram", event.target.value))}
                            placeholder="Ex: portalvegano.insta"
                            replace={/(?![A-Za-z0-9._-])./g}
                        />

                        <Input
                            defaultValue={cadastro.facebook}
                            invalid={!isValid.facebook}
                            label={<span><FaFacebook /> Facebook:</span>}
                            maxLength="160"
                            name="facebook"
                            onChange={event => dispatch(setCadastro("facebook", event.target.value))}
                            placeholder="Ex: oportalvegano"
                            replace={/(?![A-Za-z0-9._-])./g}
                        />
                    </div>

                    <Input
                        defaultValue={cadastro.site}
                        invalid={!isValid.site}
                        label="Seu site:"
                        legend="Copie e cole o link para seu site aqui"
                        name="site"
                        onChange={event => dispatch(setCadastro("site", event.target.value))}
                        placeholder="Ex: https://portalvegano.com.br/"
                        replace=" "
                    />
                </fieldset>

                <div style={{ display: "flex", justifyContent: "space-between", marginTop: '4rem' }}>
                    <Button backgroundColor="hsl(255, 0%, 30%)" to="/cadastro/1">Voltar</Button>

                    <Button type="submit">Continuar</Button>
                </div>
            </form>
        </div>
    );
};

export default Cadastro2Screen;