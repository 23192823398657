// Dependências
import React, { useState } from "react";
import { FaSearch } from "react-icons/fa";
import { withRouter } from "react-router-dom";

// Css
import classes from "./SearchBar.module.css";


const SearchBar = ({ history }) => {
    const [search, setSearch] = useState("");

    const submitSearch = () => {
        if (search.length)
            history.push(`/servicos?search=${search}`);
    };

    return (
        <div className={classes.Wrapper}>
            <FaSearch className={classes.SearchIcon} />

            <input
                autoCapitalize="off"
                autoComplete="off"
                autoCorrect="off"
                className={classes.Input}
                type="text"
                aria-label="Digite aqui o que procura"
                maxLength="120"
                name="cidade"
                placeholder="Que serviço você busca?"
                onChange={event => setSearch(event.target.value)}
                onKeyDown={event => {
                    if (event.key === "Enter") {
                        if (search.length) submitSearch();
                        else event.target.blur();
                    };
                }}
                spellCheck="off"
            />
        </div>
    );
};

export default withRouter(SearchBar);