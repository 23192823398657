// Dependências
import React from "react";


const ItemsList = ({ items, icon }) => {
    return items.length && items[0] ? (
        <div>
            {icon}
            <span>{items.map((item, index) => `${item} ${index === items.length - 1 ? "" : " | "}`)}</span>
        </div>
    ) : null;
};

export default ItemsList;
