// Actions
import {
    SET_CADASTRO
} from "../actions/cadastro";

const initialState = {
    nome: "",
    cnpj: "",
    caracteristicas: [],
    categoria: "",
    descricao: "",
    disponibilidade: {
        domingo: false,
        segunda: true,
        terca: true,
        quarta: true,
        quinta: true,
        sexta: true,
        sabado: false
    },
    servicos: [],
    horaFim: "18:00",
    horaInicio: "08:00",
    subcategoria: "",
    celular: "",
    celular2: "",
    telefone: "",
    email: "",
    facebook: "",
    instagram: "",
    site: ""
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CADASTRO:
            return { ...action.cadastro };
        default:
            return state;
    };
};

export default authReducer;