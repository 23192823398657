// Dependências
import React from "react";
import { FaMapMarkerAlt } from "react-icons/fa";
import { Link } from "react-router-dom";

// Css
import classes from "./CardServico.module.css";


const CardServico = ({ servico, widthAuto }) => {
    return (
        <Link to={`/servicos/${servico.id}`} className={`${classes.Wrapper} ${widthAuto ? classes.WidthAuto : ""}`} style={!servico.imagem.caminho ? { opacity: 0.8 } : {}} >
            <div className={classes.ImageWrapper} style={{ background: "url(/images/logo.png)" }}>
                <img src={servico.imagem.caminho} alt="" />
            </div>

            <div className={classes.Title}>{servico.nome}</div>
            <div className={classes.Legend}>{servico?.categoria?.nome}</div>
            <div className={classes.Legend}><FaMapMarkerAlt /> {servico.endereco.estado.nome}{servico.endereco.cidade.nome && servico.endereco.estado.nome ? "," : ""} {servico.endereco.cidade.nome}</div>
        </Link >
    );
};

export default CardServico;