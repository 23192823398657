// Dependências
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Actions
import { setCadastro } from "../../store/actions/cadastro";

// Css
import classes from "./CadastroScreen.module.css";

// Components
import Button from "../../components/UI/Button/Button";
import Dropdown from "../../components/UI/Dropdown/Dropdown";
import Input from "../../components/UI/Input/Input";

// Helpers
import * as validator from "../../helpers/validator";

// Hooks
import useAxios from "../../hooks/useAxios";
import useGet from "../../hooks/useGet";


const CadastroScreen = ({ history }) => {
    const dispatch = useDispatch();
    const cadastro = useSelector(state => state.cadastro);

    const resultCategorias = useGet("/servicos/categorias");
    const subcategoriasReq = useAxios(`GET /servicos/categorias/`);

    const [listSubcategorias, setListSubcategorias] = useState([]);
    const [isValid, setIsValid] = useState({
        nome: true,
        cnpj: true,
        categoria: true,
        subcategoria: true
    });

    const getSubcategorias = useCallback(async idCategoria => {
        const result = await subcategoriasReq({ urlParams: idCategoria });
        if (result.status === 200)
            if (result.data.categorias.length)
                setListSubcategorias(prevListSubcategorias => [...prevListSubcategorias, result.data.categorias]);
    }, [subcategoriasReq]);

    const submitHandler = event => {
        event.preventDefault();
        const copiaIsValid = { ...isValid };

        copiaIsValid.nome = validator.name(cadastro.nome);
        copiaIsValid.categoria = cadastro.categoria?.[0] ? true : false;
        copiaIsValid.cnpj = cadastro.cnpj ? validator.cnpj(cadastro.cnpj) : true;
        copiaIsValid.subcategoria = cadastro.categoria.length === listSubcategorias.length + 1 ? true : false;

        setIsValid({ ...copiaIsValid });

        // Se não existir nenhum valor inválido
        if (!Object.values(copiaIsValid).includes(false)) history.push("/cadastro/2");
    };

    // Faz os requests das categorias
    useEffect(() => {
        if (resultCategorias.categorias && !listSubcategorias.length && cadastro.categoria) {
            const selectedCategoria = resultCategorias.categorias.find(categoria => categoria.id === cadastro.categoria[0]);

            if (selectedCategoria.hasChildrens)
                for (let i = 0; i < cadastro.categoria.length; i++) getSubcategorias(cadastro.categoria[i]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resultCategorias]);

    return (
        <div style={{ padding: "0 .5rem" }}>
            <h1>Cadastre seu Serviço</h1>

            <form onSubmit={submitHandler}>
                <fieldset className={classes.Fieldset}>
                    <legend>Informações básicas</legend>

                    <div className={classes.Main}>
                        <Input
                            defaultValue={cadastro.nome}
                            errorMessage="O nome informado não é válido"
                            invalid={!isValid.nome}
                            label="Nome:"
                            legend="Este será o nome de exibição do seu negócio."
                            onChange={event => dispatch(setCadastro("nome", event.target.value))}
                            placeholder="Nome de exibição"
                            required
                        />

                        <Input
                            defaultValue={cadastro.cnpj}
                            errorMessage="O CNPJ informado não é válido"
                            invalid={!isValid.cnpj}
                            label="CNPJ:"
                            mask="99.999.999/9999-99"
                            name="cnpj"
                            onChange={event => dispatch(setCadastro("cnpj", event.target.value.replace(/(\D)+/g, "")))}
                        />

                        {resultCategorias.categorias ? (
                            <>
                                <Dropdown
                                    defaultValue={cadastro.categoria[0]}
                                    errorMessage="Selecione um serviço antes de prosseguir"
                                    invalid={!isValid.categoria}
                                    label="Serviço prestado:"
                                    options={[{ id: 0, nome: "Selecione um serviço" }, ...resultCategorias.categorias]}
                                    onChange={event => {
                                        if (!parseInt(event.target.value)) return;

                                        const selectedCategoria = resultCategorias.categorias.find(categoria => categoria.id === parseInt(event.target.value));
                                        setListSubcategorias([]);
                                        if (selectedCategoria.hasChildrens) getSubcategorias(selectedCategoria.id);
                                        dispatch(setCadastro("categoria", [parseInt(event.target.value)]));
                                    }}
                                    required
                                />

                                {listSubcategorias.length ? listSubcategorias.map((subcategorias, index) => (
                                    <Dropdown
                                        log
                                        key={subcategorias[0].id}
                                        defaultValue={cadastro.categoria[index + 1]}
                                        invalid={(index + 1 === listSubcategorias.length) && !isValid.subcategoria}
                                        label="Especialidade:"
                                        onChange={event => {
                                            if (!parseInt(event.target.value)) return;

                                            const selectedSubcategoria = subcategorias.find(subcategoria => subcategoria.id === parseInt(event.target.value));

                                            setListSubcategorias(prevListSubcategorias => {
                                                let copiaPrevListSubcategorias = [...prevListSubcategorias];
                                                copiaPrevListSubcategorias.length = index + 1;
                                                return copiaPrevListSubcategorias;
                                            });
                                            if (selectedSubcategoria.hasChildrens) getSubcategorias(selectedSubcategoria.id);

                                            let copiaCategoria = [...cadastro.categoria];
                                            copiaCategoria.length = index + 1;
                                            dispatch(setCadastro("categoria", [...copiaCategoria, selectedSubcategoria.id]));
                                        }}
                                        options={[{ id: 0, nome: "Selecione uma especialidade" }, ...subcategorias]}
                                        required
                                    />
                                )) : null}
                            </>
                        ) : null}
                    </div>
                </fieldset>

                <div style={{ textAlign: 'right', marginTop: '4rem' }}>
                    <Button type="submit">Continuar</Button>
                </div>
            </form>
        </div>
    );
};

export default CadastroScreen;