// Cria uma string aleatoria com o tamanho informado
const randomString = (length = 12) => {
    try {
        let result = "";
        const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

        for (let i = 0; i < length; i++)
            result += characters.charAt(Math.floor(Math.random() * characters.length));

        return result;
    } catch (error) {
        throw error;
    };
};

export default randomString;