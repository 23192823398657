// Dependências
import React, { useState } from "react";

// Css
import classes from "./ModalBody.module.css";


const ModalBody = ({ images }) => {
    const [selectedImgIndex, setSelectedImgIndex] = useState(0);

    return (
        <div className={classes.Wrapper}>
            <div className={classes.Selector}>
                {images.map((image, index) => <img key={index} src={image} alt="" onClick={() => setSelectedImgIndex(index)} />)}
            </div>

            <div className={classes.SelectedImg}>
                <img className={classes.MainImage} src={images[selectedImgIndex]} alt="" />
            </div>
        </div>
    );
};

export default ModalBody;