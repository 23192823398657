// Dependências
import React, { useEffect, memo } from "react";
import ReactDOM from "react-dom";
import { AiOutlineClose } from "react-icons/ai";
import { CSSTransition } from "react-transition-group";

// Css
import classes from "./Modal.module.css";


const Modal = props => {
    // Fecha o modal ao apertar a tecla ESC
    useEffect(() => {
        const closeOnEscapeKeyDown = event => {
            if ((event.charCode || event.keyCode) === 27) props.onClose()
        };

        document.body.addEventListener('keydown', closeOnEscapeKeyDown);
        return () => document.body.removeEventListener('keydown', closeOnEscapeKeyDown);
    }, [props]);

    return ReactDOM.createPortal(
        <CSSTransition
            in={props.show}
            unmountOnExit
            timeout={{ enter: 0, exit: 300 }}
            classNames={{
                enterDone: classes.EnterDone,
                exit: classes.Exit
            }}
        >
            <div className={classes.Modal} onClick={props.onClose}>
                <div className={classes.Content} onClick={event => event.stopPropagation()}>
                    <div className={classes.Header}>
                        <span>{props.title}</span>
                        <button
                            className={classes.CloseButton}
                            title="Fechar"
                            onClick={props.onClose}
                        >
                            <AiOutlineClose />
                        </button>
                    </div>

                    <div className={classes.Body}>
                        {props.children}
                    </div>

                    {props.footer ? (
                        <div className={classes.Footer}>{props.footer}</div>
                    ) : null}
                </div>
            </div>
        </CSSTransition>,
        document.getElementById('root')
    );
};

export default memo(Modal);