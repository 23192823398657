// Dependências
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';

// Actions
import { setPerfil } from "../../../store/actions/perfil";
import { setCadastro } from "../../../store/actions/cadastro";

// Css
import classes from "./Cadastro4Screen.module.css";

// Components
import Button from "../../../components/UI/Button/Button";
import DiaSemana from "../../../components/Cadastro/DiaSemana/DiaSemana";
import Input from "../../../components/UI/Input/Input";
import Textarea from "../../../components/UI/Textarea/Textarea";

// Helpers
import { success } from "../../../helpers/sweetAlert";

// Hooks
import useAxios from "../../../hooks/useAxios";
import useGet from "../../../hooks/useGet";


const Cadastro4Screen = ({ history }) => {
    const dispatch = useDispatch();
    const cadastro = useSelector(state => state.cadastro);

    const caracteristicas = useGet("/servicos/caracteristicas", { storage: true });
    const postServicoReq = useAxios("POST /servico", { formDataName: "servico", sendFormData: true });

    const [newCaracteristica, setNewCaracteristica] = useState("");
    const [newServico, setNewServico] = useState("");

    const [isNewServicoValid, setIsNewServicoValid] = useState(true);
    const [isNewCaracteristicaValid, setIsNewCaracteristicaValid] = useState(true);


    // Valida se os passos do 1º cadastro foram cumpridos
    useEffect(() => {
        if (!cadastro.nome || !cadastro.categoria) history.push("/cadastro/1");
        else if (!cadastro.estado || !cadastro.cidade) history.push("/cadastro/3");
    }, [cadastro, history]);

    const createCaracteristica = () => {
        if ((newCaracteristica.length <= 4) || (cadastro.caracteristicas.includes(newCaracteristica))) {
            setIsNewCaracteristicaValid(false);
            return;
        };

        dispatch(setCadastro("caracteristicas", [...cadastro.caracteristicas, newCaracteristica]));
        setNewCaracteristica("");
        setIsNewCaracteristicaValid(true);
    };

    const createServico = () => {
        if (newServico.length > 1) {
            dispatch(setCadastro("servicos", [...cadastro.servicos, newServico]));
            setNewServico("");
            setIsNewServicoValid(true);
        } else
            setIsNewServicoValid(false);
    };

    const submitHandler = async event => {
        event.preventDefault();

        const data = {
            nome: cadastro.nome,
            categoria: cadastro.categoria[cadastro.categoria.length - 1],
            cnpj: cadastro.cnpj || null,
            caracteristicas: cadastro.caracteristicas,
            contatos: {
                celulares: [],
                telefones: [],
                whatsapps: [],
                email: cadastro.email,
                instagram: cadastro.instagram,
                facebook: cadastro.facebook,
                site: cadastro.site,
            },
            descricao: cadastro.descricao || "",
            disponibilidade: { ...cadastro.disponibilidade },
            endereco: {
                bairro: cadastro.bairro || null,
                cep: cadastro.cep || null,
                cidade: cadastro.cidade,
                complemento: cadastro.complemento || null,
                estado: cadastro.estado,
                numero: cadastro.numero || null,
                rua: cadastro.rua || null
            },
            horaFim: cadastro.horaFim || null,
            horaInicio: cadastro.horaInicio || null,
            servicos: cadastro.servicos
        };

        if (cadastro.telefone) data.contatos.telefones.push(cadastro.telefone);
        if (cadastro.celular) data.contatos.celulares.push(cadastro.celular);
        if (cadastro.celular2) data.contatos.whatsapps.push(cadastro.celular2);

        const result = await postServicoReq({ body: data });

        if (result.status === 201) {
            dispatch(setPerfil(result.data));
            success({
                title: "Serviço criado!",
                text: "Seu serviço já está disponível no nosso site! Aqui você pode fazer outras alterações para melhorar a página do seu perfil"
            });
            history.push("/meu-perfil");
        };
    };

    return (
        <div style={{ padding: "0 .5rem" }}>
            <h1>Cadastre seu Serviço</h1>
            <form onSubmit={submitHandler}>
                <fieldset className={classes.Fieldset}>
                    <legend>Sobre seu serviço</legend>

                    <div className={classes.DisponibilidadeWrapper}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span style={{ marginTop: '1.5rem' }}>Horário de funcionamento</span>

                            <div>
                                <Input
                                    defaultValue={cadastro.horaInicio}
                                    mask="99:99"
                                    onChange={event => dispatch(setCadastro("horaInicio", event.target.value))}
                                    placeholder="Inicio"
                                    inputStyle={{ width: "4rem" }}
                                />
                            </div>
                            <span style={{ marginTop: '1.5rem' }}>-</span>
                            <div>
                                <Input
                                    defaultValue={cadastro.horaFim}
                                    mask="99:99"
                                    onChange={event => dispatch(setCadastro("horaFim", event.target.value))}
                                    placeholder="Fim"
                                    inputStyle={{ width: "4rem" }}
                                />
                            </div>
                        </div>

                        <div className={classes.DaysWrapper}>
                            <div>Dias de funcionamento</div>

                            {Object.keys(cadastro.disponibilidade).map(diaSemana => (
                                <DiaSemana
                                    key={diaSemana}
                                    dia={diaSemana}
                                    onChange={event => dispatch(setCadastro("disponibilidade", { ...cadastro.disponibilidade, [diaSemana]: event.target.checked }))}
                                    value={cadastro.disponibilidade[diaSemana]}
                                />
                            ))}
                        </div>
                    </div>

                    <div className={classes.Group} style={{ gap: '1rem' }}>
                        <div className={classes.Caracteristicas}>
                            <span>Seu estabelecimento oferece:</span>
                            <ul>
                                {caracteristicas.map(caracteristica => (
                                    <li key={caracteristica.id}>
                                        <input
                                            id={`caracteristica-${caracteristica.id}`}
                                            defaultChecked={cadastro.caracteristicas.map(_caracteristica => _caracteristica.id)?.includes(caracteristica.id)}
                                            type="checkbox" onChange={event => {
                                                if (event.target.checked)
                                                    dispatch(setCadastro("caracteristicas", [...cadastro.caracteristicas, caracteristica.id]));
                                                else
                                                    dispatch(setCadastro("caracteristicas", cadastro.caracteristicas.filter(prevCaracteristica => prevCaracteristica !== caracteristica.id)));
                                            }}
                                        />
                                        <label htmlFor={`caracteristica-${caracteristica.id}`} style={{ cursor: 'pointer' }}>{caracteristica.nome}</label>
                                    </li>
                                ))}
                                {cadastro.caracteristicas.filter(caracteristica => typeof caracteristica === 'string').map(caracteristica => (
                                    <li key={caracteristica}>
                                        <input
                                            id={`caracteristica-${caracteristica}`}
                                            defaultChecked={true}
                                            type="checkbox"
                                            onChange={() => dispatch(setCadastro("caracteristicas", cadastro.caracteristicas.filter(prevCaracteristica => prevCaracteristica !== caracteristica)))}
                                        />
                                        <label htmlFor={`caracteristica-${caracteristica}`} style={{ cursor: 'pointer' }}>{caracteristica}</label>
                                    </li>
                                ))}
                            </ul>

                            <div style={{ display: 'flex' }}>
                                <Input
                                    enterHandler={event => {
                                        event.preventDefault();
                                        createCaracteristica();
                                    }}
                                    invalid={!isNewCaracteristicaValid}
                                    maxLength="60"
                                    onChange={event => setNewCaracteristica(event.target.value)}
                                    placeholder="Digite mais aqui"
                                    type="text"
                                    value={newCaracteristica}
                                />

                                <Button
                                    style={{ alignSelf: 'center' }}
                                    onClick={createCaracteristica}
                                >Salvar</Button>
                            </div>
                        </div>

                        <div className={classes.Servicos}>
                            <span>Serviços disponíveis no seu estabelecimento:</span>
                            <ul>
                                {cadastro.servicos.map((servico, index) => (
                                    <li key={index}>
                                        <button
                                            type="button"
                                            className={classes.ServicosDeleteButton}
                                            onClick={() => dispatch(setCadastro("servicos", cadastro.servicos.filter((prevServico, _index) => _index !== index)))}
                                        />
                                        <span>{servico}</span>
                                    </li>
                                ))}
                            </ul>
                            <div style={{ display: 'flex' }}>
                                <Input
                                    enterHandler={event => {
                                        event.preventDefault();
                                        createServico();
                                    }}
                                    invalid={!isNewServicoValid}
                                    maxLength="60"
                                    onChange={event => setNewServico(event.target.value)}
                                    placeholder="Digite um serviço aqui"
                                    type="text"
                                    value={newServico}
                                />

                                <Button
                                    style={{ alignSelf: 'center' }}
                                    onClick={createServico}
                                >Salvar</Button>
                            </div>
                        </div>
                    </div>

                    <Textarea
                        defaultValue={cadastro.descricao}
                        label="Descrição"
                        legend="Esse texto será exibido no seu perfil"
                        maxLength="1200"
                        onChange={event => dispatch(setCadastro("descricao", event.target.value))}
                        placeholder={"Diga-nos um pouco sobre seu serviço..."}
                        rows="6"
                    />
                </fieldset>

                <div style={{ display: "flex", justifyContent: "space-between", marginTop: '4rem' }}>
                    <Button backgroundColor="hsl(255, 0%, 30%)" to="/cadastro/3">Voltar</Button>

                    <Button type="submit">Concluir cadastro</Button>
                </div>
            </form>
        </div>
    );
};

export default Cadastro4Screen;