// Actions
import { REMOVE_TOKEN, SAVE_TOKEN } from "../actions/auth";


const initialState = {
    token: JSON.parse(localStorage.getItem("bearerToken")) || null,
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_TOKEN:
            localStorage.setItem("bearerToken", JSON.stringify(action.token));
            return { ...state, token: action.token };
        case REMOVE_TOKEN:
            return { ...state, token: null };
        default:
            return state;
    };
};

export default authReducer;