// Dependências
import React from "react";

// Css
import classes from "./LoadingCategoria.module.css";


const LoadingCategoria = () => {
    return (
        <div className={classes.Wrapper}>
            <div className={classes.Image} />

            <div className={classes.TextWrapper}>
                <div className={classes.Text} />
            </div>
        </div>
    );
};

export default LoadingCategoria;