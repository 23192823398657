// Dependências
import React, { memo } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

// Components
import ScrollToTop from "../components/UI/ScrollToTop/ScrollToTop";

// Screens
import CadastroScreen from './Cadastro/CadastroScreen';
import Cadastro2Screen from './Cadastro/Cadastro2/Cadastro2Screen';
import Cadastro3Screen from './Cadastro/Cadastro3/Cadastro3Screen';
import Cadastro4Screen from './Cadastro/Cadastro4/Cadastro4Screen';

import CategoriasScreen from './Categorias/CategoriasScreen';

import HomeScreen from './Home/HomeScreen';

import LoginScreen from './Login/LoginScreen';
import MeuPerfilScreen from './MeuPerfil/MeuPerfilScreen';

import ServicosScreen from './Servicos/ServicosScreen';
import ServicoScreen from './Servico/ServicoScreen';


const Navigator = () => {
    const CadastroNavigation = () => (
        <Switch>
            <Route path="/cadastro/1" component={CadastroScreen} />
            <Route path="/cadastro/2" component={Cadastro2Screen} />
            <Route path="/cadastro/3" component={Cadastro3Screen} />
            <Route path="/cadastro/4" component={Cadastro4Screen} />
            <Redirect to="/cadastro/1" />
        </Switch>
    );

    return (
        <>
            <ScrollToTop />
            <Switch>
                <Route path="/cadastro" component={CadastroNavigation} />
                <Route path="/categorias/:id" component={CategoriasScreen} />
                <Route path="/home" component={HomeScreen} />
                <Route path="/login" component={LoginScreen} />
                <Route path="/meu-perfil" component={MeuPerfilScreen} />
                <Route path="/servicos/:id" component={ServicoScreen} />
                <Route path="/servicos" component={ServicosScreen} />
                <Redirect to="/home" />
            </Switch>
        </>
    );
};

export default memo(Navigator);