// Dependências
import React, { useState } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { IoMdArrowDropdown } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

// Actions
import { removeToken } from "../../store/actions/auth";

// Css
import classes from "./Navbar.module.css";

const REDIRECT_URL = process.env.REACT_APP_REDIRECT_URL;

const Navbar = ({ onHamburgerButtonClick, showSideDrawer }) => {
    const dispatch = useDispatch();

    const hasPerfil = useSelector(state => state.perfil.hasPerfil);
    const token = useSelector(state => state.auth.token);

    const [showDropMenu, setShowDropMenu] = useState(false);

    return (
        <header className={classes.Header}>
            <div className={classes.LogoWrapper}>
                <NavLink to="/home">
                    <img className={classes.Logo} src="/images/logo.png" alt="Portal Vegano" />
                </NavLink>
            </div>

            <nav className={classes.Navigation}>
                <ul>
                    <li><a href="https://portalvegano.com.br/">Loja</a></li>

                    {hasPerfil ? (
                        <li style={{ position: "relative" }}>
                            <button className={classes.DropButton} onClick={() => setShowDropMenu(prevShowDropMenu => !prevShowDropMenu)}>
                                Meu Perfil <IoMdArrowDropdown style={{ verticalAlign: 'bottom' }} />
                            </button>

                            {showDropMenu ? (
                                <ul className={classes.DropMenu}>
                                    <li style={{ padding: "0" }} onClick={() => setShowDropMenu(false)}>
                                        <NavLink to="/meu-perfil" style={{ display: "block", padding: ".3rem .8rem", textDecoration: "none", color: 'inherit' }}>
                                            Editar
                                        </NavLink>
                                    </li>

                                    <li style={{ padding: "0" }} onClick={() => setShowDropMenu(false)}>
                                        <button className={classes.LogoutButton} onClick={() => dispatch(removeToken())}>
                                            <span style={{ display: "block", padding: ".3rem .8rem" }}>
                                                Sair
                                            </span>
                                        </button>
                                    </li>
                                </ul>
                            ) : null}
                        </li>
                    ) : !token ? (
                        <li><a href={`https://portalvegano.com.br/login?redirecionar=${btoa(REDIRECT_URL)}`}>Entrar</a></li>
                    ) : (
                        <NavLink to="/cadastro" style={{ textDecoration: "none", color: 'inherit' }}>Criar Serviço</NavLink>
                    )}
                </ul>
            </nav>

            <button className={classes.HamburgerButton} onClick={onHamburgerButtonClick}>
                {showSideDrawer ? <AiOutlineClose /> : <AiOutlineMenu />}
            </button>
        </header>
    );
};

export default Navbar;