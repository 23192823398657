// Dependências
import React, { useEffect, useMemo, useRef, useState } from "react";

// Components
import FilePreview from "./FilePreview/FilePreview";

// Css
import classes from "./Dropzone.module.css";

// Helpers
import randomString from "../../../helpers/randomString";


const Dropzone = props => {
    const defaultAccept = useMemo(() => ["jpg", "jpeg", "png", "webp"], []);
    const idInput = useMemo(() => randomString(), []);

    const dragItem = useRef();

    const [files, setFiles] = useState([]);
    const [isDragging, setIsDragging] = useState(false);


    const onFileUpload = async fileList => {
        // Transforma a fileList em um array de files
        let arrNewFiles = [];
        for (let i = 0; i < fileList.length; i++) arrNewFiles.push({ key: randomString(), value: fileList[i] });
        setFiles(prevFiles => [...prevFiles, ...arrNewFiles]);
    };

    const onDragEnterHandler = key => {
        if (dragItem.current === key) return;

        const draggingItemIndex = files.map(file => file.key).indexOf(dragItem.current);
        const currentItemIndex = files.map(file => file.key).indexOf(key);

        setFiles(oldFiles => {
            const newFiles = [...oldFiles];
            newFiles.splice(currentItemIndex, 0, newFiles.splice(draggingItemIndex, 1)[0]);

            return newFiles;
        });
    };

    // Seta o valor inicial de files
    useEffect(() => {
        if (files.length || props.defaultValue)
            setFiles(prevFiles => [...prevFiles, ...props.defaultValue?.map?.(file => ({ ...file, key: randomString(), type: 'default' }))])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Quando mudar o file e não tiver arrastando, chama onChange
    useEffect(() => {
        if (!isDragging) props.onChange?.(files.map(file => ({ ...file, type: file.type || 'uploaded', value: file.value })));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDragging, files]);


    return (
        <div className={classes.Wrapper}>
            <div style={{ marginBottom: '.3rem' }}>{props.label}</div>

            <div
                className={classes.DropWrapper}
                onDragEnter={event => event.preventDefault()}
                onDragLeave={event => event.preventDefault()}
                onDragOver={event => event.preventDefault()}
                onDrop={event => {
                    event.preventDefault();
                    onFileUpload(event.dataTransfer.files)
                }}
            >
                {files.length ? (
                    <ul className={classes.FilePreviewList}>
                        {files.map(file => (
                            <FilePreview
                                accept={props.accept || defaultAccept}
                                key={file.key}
                                file={file.value}
                                name={file.value?.name || file.name}
                                onDelete={() => setFiles(prevFiles => prevFiles.filter(prevFile => prevFile.key !== file.key))}
                                onDragEnd={() => setIsDragging(false)}
                                onDragEnter={() => onDragEnterHandler(file.key)}
                                onDragStart={() => { setIsDragging(true); dragItem.current = file.key; }}
                                type={file.type}
                            />
                        ))}
                    </ul>
                ) : (
                    <span className={classes.Instructions}>{props.instructions || 'Arraste os arquivos aqui ou clique abaixo para selecionar.'}</span>
                )}

                <input
                    accept={props.accept || defaultAccept}
                    id={idInput}
                    multiple
                    onChange={event => { onFileUpload(event.target.files); event.target.value = ""; }}
                    style={{ display: 'none' }}
                    type="file"
                />

                <button className={classes.Button} type="button">
                    <label htmlFor={idInput}> Adicionar imagem </label>
                </button>
            </div>
        </div>
    );
};

export default Dropzone;