// Dependências
import axios from 'axios';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Actions
import { createRequest } from "../store/actions/requests";

// Helpers
import { error as alertError } from "../helpers/sweetAlert";


const BASE_URL = process.env.REACT_APP_BASE_URL;


const useAxios = (methodUrl, options = { noErrorHandler: false, notSendToken: false, storage: false, sendFormData: false, formDataName: "" }) => {
    const dispatch = useDispatch();

    const token = useSelector(state => state.auth.token);
    const prevRequest = useSelector(state => state.requests[methodUrl]);
    const unshiftRequest = useSelector(state => state.requests[options.unshift]);
    const removeRequest = useSelector(state => state.requests[options.remove?.request]);

    const method = methodUrl.split(" ")[0];
    const url = methodUrl.replace(`${method} `, '');

    return useCallback((requestOptions = { body: undefined, files: {}, urlParams: [] }) => {
        // Se a option storage for true, ao invés de fazer um novo request irá retornar o que um request igual anterior retornou.
        if (options.storage && prevRequest) return prevRequest;

        // Criação do formData
        let formData = new FormData();
        if (options.sendFormData) {
            if (requestOptions.body) {
                if (options.formDataName)
                    formData.append(options.formDataName, JSON.stringify(requestOptions.body));
                else
                    for (const key in requestOptions.body) formData.append(key, JSON.stringify(requestOptions.body[key]));
            };

            for (const key in requestOptions.files) formData.append(key, requestOptions.files[key]);
        };

        return new Promise(async resolve => {
            axios({
                method: method,
                url: `${BASE_URL}${url}${requestOptions.urlParams ? requestOptions.urlParams : ""}`,
                headers: {
                    'Content-Type': options.sendFormData ? `multipart/form-data; boundary=${formData._boundary}` : `application/json; charset=utf-8`,
                    'authorization': options.notSendToken ? undefined : token
                },
                data: options.sendFormData ? formData : requestOptions.body
            }).then(result => {
                if (options.storage) dispatch(createRequest(methodUrl, result.data));
                resolve({ data: result.data, status: result.status });
            }).catch(error => {
                if (error.message === "Network Error") alertError({ title: "Erro de Conexão", text: "Por favor, verifique sua conexão com a internet." });
                if (!options.noErrorHandler) alertError({
                    title: `${error.response?.status ?? ""} ${error.response?.statusText ?? ""}`,
                    text: error.response?.data?.message || "Alguma coisa deu errado, tente novamente. Se o problema persistir, por favor contate-nos",
                    footer: error.response?.data?.cause ? `<strong>Cause:</strong> ${error.response.data.cause}` : null
                });
                resolve({ message: error.response?.data?.message, status: error.response?.status, cause: error.response?.data?.cause });
            });
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [methodUrl, prevRequest, removeRequest, unshiftRequest, options.token]);
};

export default useAxios;