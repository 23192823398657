// Dependências
import React from "react";

// Components
import Button from "../UI/Button/Button";

// Css
import classes from "./PermissionCard.module.css";


const PermissionCard = ({ onAccept, onReject }) => {
    return (
        <div className={classes.PermissionCard}>
            <strong style={{ display: 'block', fontSize: '1.2rem', marginBottom: '1rem' }}>Quer encontrar serviços na sua região?</strong>

            <span>
                Permita-nos saber sua localização para lhe indicarmos os melhores serviços próximos de você!
            </span>

            <div className={classes.ButtonsWrapper}>
                <Button onClick={onReject} backgroundColor="hsl(0, 0%, 80%)">Cancelar</Button>
                <Button onClick={onAccept}>Ativar localização</Button>
            </div>
        </div>
    );
};

export default PermissionCard;