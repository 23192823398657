// Dependências
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router";

// Css
import classes from "./ServicosScreen.module.css";

// Components
import Banner from "../../components/Banner/Banner";
import CardServico from "../../components/Servicos/CardServico/CardServico";
import LoadingCardServico from "../../components/Servicos/LoadingCardServico/LoadingCardServico";
import Menu from "../../components/Servicos/Menu/Menu";

// Hooks
import useAxios from "../../hooks/useAxios";
import useQuery from "../../hooks/useQuery";


const ServicosScreen = () => {
    const search = useQuery("search");
    const category = useQuery("category");
    const coordinates = useSelector(state => state.coordenadas);

    const [isLoading, setIsLoading] = useState(false);
    const [onMyLocation, setOnMyLocation] = useState(true); // Mostra serviços apenas na localização do usuário se true
    const [servicos, setServicos] = useState([]);
    const [categorias, setCategorias] = useState([]);


    const servicosReq = useAxios((() => {
        let request = `GET /servicos?quantity=20&offset=0`;
        if (onMyLocation && coordinates.latitude && coordinates.longitude)
            request += `&onMyLocation=true&latitude=${coordinates.latitude}&longitude=${coordinates.longitude}`;

        if (search) request += `&search=${search}`;

        if (category) request += `&category=${category}`;

        return request;
    })());

    // Faz request dos servicos
    useEffect(() => {
        (async () => {
            if (search) {
                setIsLoading(true);
                const result = await servicosReq();
                if (result.status === 200) {
                    setServicos(result.data?.servicos);
                    setCategorias(result.data?.categorias);
                };
                setIsLoading(false);
            };
        })();
    }, [search, servicosReq, onMyLocation]);

    return !search ? <Redirect to="/home" /> : (
        <div>
            <Banner />

            <strong className={classes.Title}>
                Resultados para "{search}".
            </strong>

            <div className={classes.Wrapper}>
                {categorias.length || (coordinates.latitude && coordinates.longitude) ? (
                    <Menu
                        categorias={categorias}
                        search={search}
                        onMyLocation={onMyLocation}
                        setOnMyLocation={setOnMyLocation}
                    />
                ) : null}

                {isLoading ? (
                    <div className={classes.ServicosWrapper}>
                        {[...Array(1)].map((arr, index) => <LoadingCardServico key={index} />)}
                    </div>
                ) : servicos.length ? (
                    <div className={classes.ServicosWrapper}>
                        {servicos.map(servico => <CardServico widthAuto key={servico.id} servico={servico} />)}
                    </div>
                ) : (
                    <div className={classes.NoResult}>
                        Nenhum resultado encontrado para "{search}".
                    </div>
                )}
            </div >
        </div >
    );
};

export default ServicosScreen;