// Dependências
import React, { useCallback, useEffect, useState } from "react";
import { FaInstagram, FaFacebook } from "react-icons/fa";
import { RiErrorWarningLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router";

// Actions
import { setPerfil } from "../../store/actions/perfil";

// Css
import classes from "./MeuPerfilScreen.module.css";

// Components
import Button from "../../components/UI/Button/Button";
import DiaSemana from "../../components/Cadastro/DiaSemana/DiaSemana";
import Dropdown from "../../components/UI/Dropdown/Dropdown";
import Dropzone from "../../components/UI/Dropzone/Dropzone";
import Input from "../../components/UI/Input/Input";
import Textarea from "../../components/UI/Textarea/Textarea";

// Helpers
import { error as alertError } from "../../helpers/sweetAlert";
import * as validator from "../../helpers/validator";

// Hooks
import useAxios from "../../hooks/useAxios";
import useGet from "../../hooks/useGet";


const MeuServicoScreen = ({ history }) => {
    const dispatch = useDispatch();

    const perfil = useSelector(state => state.perfil.perfil);
    const token = useSelector(state => state.auth.token);

    const perfilReq = useAxios(`GET /servicos/perfil`);

    const updateServicoReq = useAxios(`PUT /servicos/${perfil.id}`, { sendFormData: true, formDataName: 'servico' });
    const subcategoriasReq = useAxios(`GET /servicos/categorias/`);

    const [listSubcategorias, setListSubcategorias] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingPutRequest, setIsLoadingPutRequest] = useState(false);

    const [newCaracteristica, setNewCaracteristica] = useState("");
    const [newServico, setNewServico] = useState("");

    // States informações básicas
    const [categoria, setCategoria] = useState([]);
    const [cnpj, setCnpj] = useState("");
    const [descricao, setDescricao] = useState("");
    const [disponibilidade, setDisponibilidade] = useState({
        domingo: false,
        segunda: true,
        terca: true,
        quarta: true,
        quinta: true,
        sexta: true,
        sabado: false
    });
    const [horaFim, setHoraFim] = useState("");
    const [horaInicio, setHoraInicio] = useState("");
    const [valorMinimo, setValorMinimo] = useState("");
    const [valorMaximo, setValorMaximo] = useState("");
    const [nome, setNome] = useState("");
    const [imagens, setImagens] = useState([]);
    const [newCaracteristicas, setNewCaracteristicas] = useState([]);
    const [servicos, setServicos] = useState([]);

    // States contatos
    const [celulares, setCelulares] = useState(["", "", ""]);
    const [email, setEmail] = useState("");
    const [facebook, setFacebook] = useState("");
    const [instagram, setInstagram] = useState("");
    const [site, setSite] = useState("");
    const [telefones, setTelefones] = useState(["", "", ""]);
    const [whatsapps, setWhatsapps] = useState(["", "", ""]);

    // States de endereço
    const [bairro, setBairro] = useState("");
    const [cep, setCep] = useState("");
    const [cidade, setCidade] = useState(0);
    const [complemento, setComplemento] = useState("");
    const [estado, setEstado] = useState(0);
    const [numero, setNumero] = useState("");
    const [rua, setRua] = useState("");

    const [isValid, setIsValid] = useState({
        categoria: true,
        subcategoria: true,
        cnpj: true,
        horaInicio: true,
        horaFim: true,
        valorMinimo: true,
        valorMaximo: true,
        nome: true,
        newCaracteristica: true,
        newServico: true,

        celulares: [true, true, true],
        email: true,
        facebook: true,
        instagram: true,
        site: true,
        telefones: [true, true, true],
        whatsapps: [true, true, true],

        cep: true,
        cidade: true,
        estado: true,
    });

    const caracteristicas = useGet(token ? "/servicos/caracteristicas" : null, { storage: true });
    const estados = useGet("/estados", { storage: true });
    const cidades = useGet(estado ? `/estados/${estado}/cidades` : null, { storage: true });

    const resultCategorias = useGet("/servicos/categorias");

    // Faz o request completo do perfil
    useEffect(() => {
        const prefilReq_ = async () => {
            const result = await perfilReq();
            setIsLoading(true);

            if (result.status === 200) dispatch(setPerfil(result.data));
            else history.push("/home");
            setIsLoading(false);
        };

        if (perfil.id && !perfil.categoria) prefilReq_();
    }, [dispatch, history, perfil.categoria, perfil.id, perfilReq]);

    // Seta os states baseado no perfil
    useEffect(() => {
        if (perfil.id && perfil.categoria) {
            setIsLoading(true);

            setCategoria([...perfil.categoria.path.map(categoria => categoria.id)].reverse());
            setCnpj(perfil.cnpj);
            setDescricao(perfil.descricao);
            setDisponibilidade({ ...perfil.disponibilidade });
            setHoraFim(perfil.horaFim);
            setHoraInicio(perfil.horaInicio);
            setValorMinimo(perfil.valorMinimo);
            setValorMaximo(perfil.valorMaximo);
            setNome(perfil.nome);
            setNewCaracteristicas(perfil.caracteristicas.map(caracteristica => caracteristica.id));
            setServicos([...perfil.servicos]);

            setCelulares([...[...Array(3)].map((arr, index) => perfil.contatos.celulares?.[index] || "")]);
            setTelefones([...[...Array(3)].map((arr, index) => perfil.contatos.telefones?.[index] || "")]);
            setWhatsapps([...[...Array(3)].map((arr, index) => perfil.contatos.whatsapps?.[index] || "")]);
            setEmail(perfil.contatos.email);
            setFacebook(perfil.contatos.facebook);
            setInstagram(perfil.contatos.instagram);
            setSite(perfil.contatos.site);

            setBairro(perfil.endereco.bairro);
            setCep(perfil.endereco.cep);
            setCidade(perfil.endereco.cidade.id);
            setComplemento(perfil.endereco.complemento);
            setEstado(perfil.endereco.estado.id);
            setNumero(perfil.endereco.numero);
            setRua(perfil.endereco.rua);

            setIsLoading(false);
        };
    }, [perfil]);

    const createCaracteristica = () => {
        if ((newCaracteristica.length <= 2) || (newCaracteristicas.includes(newCaracteristica))) {
            setIsValid(prevIsValid => ({ ...prevIsValid, newCaracteristica: false }));
            return;
        };

        setNewCaracteristicas(prevNewCaracteristicas => [...prevNewCaracteristicas, newCaracteristica]);
        setNewCaracteristica("");
        setIsValid(prevIsValid => ({ ...prevIsValid, newCaracteristica: true }));
    };

    const createServico = () => {
        if (newServico.length > 1) {
            setServicos(prevServicos => [...prevServicos, newServico]);
            setNewServico("");
            setIsValid(prevIsValid => ({ ...prevIsValid, newServico: true }));
        } else
            setIsValid(prevIsValid => ({ ...prevIsValid, newServico: false }));
    };

    const submitHandler = async event => {
        setIsLoadingPutRequest(true);
        event.preventDefault();
        const copiaIsValid = { ...isValid };

        // Validações
        copiaIsValid.categoria = categoria.length ? true : false;
        copiaIsValid.subcategoria = categoria.length === listSubcategorias.length + 1 ? true : false;
        copiaIsValid.nome = validator.name(nome);
        copiaIsValid.cnpj = cnpj ? validator.cnpj(cnpj) : true;
        copiaIsValid.horaInicio = horaInicio ? validator.hour(horaInicio) : true;
        copiaIsValid.horaFim = horaFim ? validator.hour(horaFim) : true;
        copiaIsValid.valorMinimo = valorMinimo || valorMinimo ? valorMinimo < valorMaximo ? true : false : true;
        copiaIsValid.valorMaximo = valorMaximo || valorMinimo ? valorMinimo < valorMaximo ? true : false : true;

        copiaIsValid.email = email ? validator.email(email) : true;
        copiaIsValid.facebook = facebook ? facebook.length > 4 ? true : false : true;
        copiaIsValid.instagram = instagram ? instagram.length > 4 ? true : false  : true;
        copiaIsValid.site = site ? validator.link(site) : true;
        copiaIsValid.celulares = celulares.map(celular => celular ? validator.telefone(celular) : true);
        copiaIsValid.telefones = telefones.map(telefone => telefone ? validator.telefone(telefone) : true);
        copiaIsValid.whatsapps = whatsapps.map(whatsapp => whatsapp ? validator.telefone(whatsapp) : true);

        copiaIsValid.cidade = cidade ? true : false;
        copiaIsValid.estado = estado ? true : false;
        copiaIsValid.cep = cep ? validator.cep(cep) : true;

        const body = {
            caracteristicas: newCaracteristicas,
            categoria: categoria[categoria.length - 1],
            cnpj: cnpj || null,
            contatos: {
                celulares: celulares.filter(celular => celular),
                email,
                facebook,
                instagram,
                site,
                telefones: telefones.filter(telefone => telefone),
                whatsapps: whatsapps.filter(whatsapp => whatsapp)
            },
            valorMinimo: valorMinimo || null,
            valorMaximo: valorMaximo || null,
            descricao,
            disponibilidade,
            servicos,
            endereco: {
                bairro,
                cep,
                cidade,
                complemento,
                estado,
                numero,
                rua
            },
            horaFim,
            horaInicio,
            imagens: imagens.map((imagem, index) => ({ ...imagem, index: index })).filter(imagem => imagem.type !== "uploaded").map(imagem => ({ caminho: imagem.value, index: imagem.index, nome: imagem.name })),
            nome,
        };

        // Se não existir nenhum valor inválido envia o request
        if (!Object.values(copiaIsValid).includes(false)) {
            const files = {};
            imagens.filter(imagem => imagem.type === 'uploaded').map(imagem => imagem.value).forEach((imagem, index) => files[`imagem-${index}`] = imagem);

            const result = await updateServicoReq({
                body,
                files
            });

            if (result.status === 200) {
                dispatch(setPerfil(result.data));
                history.push(`/servicos/${result.data.id}`);
            };
        } else alertError({ title: "Formulário inválido", text: "Por favor, verifique os campos destacados em vermelho e os preencha corretamente." });

        setIsValid(copiaIsValid);
        setIsLoadingPutRequest(false);
    };

    const getSubcategorias = useCallback(async idCategoria => {
        const result = await subcategoriasReq({ urlParams: idCategoria });
        if (result.status === 200)
            if (result.data.categorias.length)
                setListSubcategorias(prevListSubcategorias => [...prevListSubcategorias, result.data.categorias]);
    }, [subcategoriasReq]);

    // Faz os requests das categorias
    useEffect(() => {
        if (resultCategorias.categorias && !listSubcategorias.length && perfil.categoria) {
            for (let i = 0; i < perfil.categoria.path.length; i++) getSubcategorias(perfil.categoria.path[i].id);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resultCategorias, perfil]);

    return !token ? <Redirect to="/home" /> : isLoading ? null : (
        <form style={{ padding: "0 .5rem" }} onSubmit={submitHandler}>

            <fieldset className={classes.Fieldset}>
                <legend>Informações básicas</legend>

                <div className={classes.Group}>
                    <Input
                        defaultValue={nome}
                        invalid={!isValid.nome}
                        label="Nome:"
                        legend="Este é o nome de exibição do seu negócio."
                        name="nome"
                        onChange={event => setNome(event.target.value)}
                        placeholder="Nome"
                        required
                    />

                    <Input
                        defaultValue={cnpj}
                        invalid={!isValid.cnpj}
                        label="CNPJ:"
                        mask="99.999.999/9999-99"
                        name="cnpj"
                        onChange={event => setCnpj(event.target.value.replace(/(\D)+/g, ""))}
                        placeholder="CNPJ"
                    />
                </div>

                {resultCategorias.categorias ? (
                    <div>
                        <Dropdown
                            defaultValue={categoria[0]}
                            invalid={!isValid.categoria}
                            label="Serviço prestado:"
                            onChange={event => {
                                if (!parseInt(event.target.value)) return;

                                const selectedCategoria = resultCategorias.categorias.find(categoria => categoria.id === parseInt(event.target.value));
                                setListSubcategorias([]);
                                if (selectedCategoria.hasChildrens) getSubcategorias(selectedCategoria.id);
                                setCategoria([parseInt(event.target.value)]);
                            }}
                            options={[...resultCategorias.categorias]}
                            required
                        />

                        {listSubcategorias.length ? listSubcategorias.map((subcategorias, index) => (
                            <Dropdown
                                key={subcategorias[0].id}
                                defaultValue={categoria[index + 1]}
                                invalid={(index + 1 === listSubcategorias.length) && !isValid.subcategoria}
                                label="Especialidade:"
                                onChange={event => {
                                    if (!parseInt(event.target.value)) return;

                                    const selectedSubcategoria = subcategorias.find(subcategoria => subcategoria.id === parseInt(event.target.value));

                                    setListSubcategorias(prevListSubcategorias => {
                                        let copiaPrevListSubcategorias = [...prevListSubcategorias];
                                        copiaPrevListSubcategorias.length = index + 1;
                                        return copiaPrevListSubcategorias;
                                    });
                                    if (selectedSubcategoria.hasChildrens) getSubcategorias(selectedSubcategoria.id);

                                    setCategoria(prevCategoria => {
                                        let copiaPrevCategoria = [...prevCategoria];
                                        copiaPrevCategoria.length = index + 1;
                                        return [...copiaPrevCategoria, selectedSubcategoria.id];
                                    });
                                }}
                                options={[{ id: 0, nome: "Selecione uma especialidade" }, ...subcategorias]}
                                required
                            />
                        )) : null}
                    </div>
                ) : null}

                <div className={classes.Group} style={{ justifyContent: 'flex-end' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span style={{ marginTop: '1.5rem', whiteSpace: 'nowrap' }}>Preço min (R$):</span>

                        <Input
                            defaultValue={valorMinimo}
                            invalid={!isValid.valorMinimo}
                            maxLength="7"
                            onChange={event => setValorMinimo(event.target.value)}
                            placeholder="Preço mínimo"
                            replace={/\D/g}
                        />
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span style={{ marginTop: '1.5rem', whiteSpace: 'nowrap' }}>Preço max (R$):</span>

                        <Input
                            defaultValue={valorMaximo}
                            invalid={!isValid.valorMaximo}
                            maxLength="7"
                            onChange={event => setValorMaximo(event.target.value)}
                            placeholder="Preço máximo"
                            replace={/\D/g}
                        />
                    </div>
                </div>

                <div className={classes.Group} style={{ gap: '1rem' }}>
                    <div className={classes.Caracteristicas}>
                        <span>Seu estabelecimento oferece:</span>
                        <ul>
                            {caracteristicas.map(caracteristica => (
                                <li key={caracteristica.id}>
                                    <input
                                        id={`caracteristica-${caracteristica.id}`}
                                        defaultChecked={perfil.caracteristicas?.map(_caracteristica => _caracteristica.id)?.includes(caracteristica.id)}
                                        type="checkbox" onChange={event => {
                                            if (event.target.checked)
                                                setNewCaracteristicas(prevNewCaracteristicas => [...prevNewCaracteristicas, caracteristica.id]);
                                            else
                                                setNewCaracteristicas(prevNewCaracteristicas => prevNewCaracteristicas.filter(prevNewCaracteristica => prevNewCaracteristica !== caracteristica.id));
                                        }}
                                    />
                                    <label htmlFor={`caracteristica-${caracteristica.id}`} style={{ cursor: 'pointer' }}>{caracteristica.nome}</label>
                                </li>
                            ))}
                            {newCaracteristicas.filter(caracteristica => typeof caracteristica === 'string').map(caracteristica => (
                                <li key={caracteristica}>
                                    <input
                                        id={`caracteristica-${caracteristica}`}
                                        defaultChecked={true}
                                        type="checkbox"
                                        onChange={() => setNewCaracteristicas(prevNewCaracteristicas => prevNewCaracteristicas.filter(prevNewCaracteristica => prevNewCaracteristica !== caracteristica))}
                                    />
                                    <label htmlFor={`caracteristica-${caracteristica}`} style={{ cursor: 'pointer' }}>{caracteristica}</label>
                                </li>
                            ))}
                        </ul>

                        <div style={{ display: 'flex' }}>
                            <Input
                                enterHandler={event => {
                                    event.preventDefault();
                                    createCaracteristica();
                                }}
                                invalid={!isValid.newCaracteristica}
                                maxLength="60"
                                onChange={event => setNewCaracteristica(event.target.value)}
                                placeholder="Digite mais aqui"
                                type="text"
                                value={newCaracteristica}
                            />

                            <Button
                                style={{ alignSelf: 'center' }}
                                onClick={createCaracteristica}
                            >Salvar</Button>
                        </div>
                    </div>

                    <div className={classes.Servicos}>
                        <span>Serviços disponíveis no seu estabelecimento:</span>
                        <ul>
                            {servicos.map((servico, index) => (
                                <li key={index}>
                                    <button
                                        type="button"
                                        className={classes.ServicosDeleteButton}
                                        onClick={() => setServicos(prevServicos => prevServicos.filter((prevServico, _index) => _index !== index))}
                                    />
                                    <span>{servico}</span>
                                </li>
                            ))}
                        </ul>
                        <div style={{ display: 'flex' }}>
                            <Input
                                enterHandler={event => {
                                    event.preventDefault();
                                    createServico();
                                }}
                                invalid={!isValid.newServico}
                                maxLength="60"
                                onChange={event => setNewServico(event.target.value)}
                                placeholder="Digite um serviço aqui"
                                type="text"
                                value={newServico}
                            />

                            <Button
                                style={{ alignSelf: 'center' }}
                                onClick={createServico}
                            >Salvar</Button>
                        </div>
                    </div>
                </div>

                <div className={classes.DisponibilidadeWrapper}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span style={{ marginTop: '1.5rem' }}>Horário de funcionamento</span>

                        <div>
                            <Input
                                defaultValue={horaInicio}
                                invalid={!isValid.horaInicio}
                                mask="99:99"
                                onChange={event => setHoraInicio(event.target.value)}
                                placeholder="Inicio"
                                inputStyle={{ width: "4rem" }}
                            />
                        </div>
                        <span style={{ marginTop: '1.5rem' }}>-</span>
                        <div>
                            <Input
                                defaultValue={horaFim}
                                invalid={!isValid.horaFim}
                                mask="99:99"
                                onChange={event => setHoraFim(event.target.value)}
                                placeholder="Fim"
                                inputStyle={{ width: "4rem" }}
                            />
                        </div>
                    </div>

                    <div className={classes.DaysWrapper}>
                        <div>Dias de funcionamento</div>

                        {Object.keys(disponibilidade).map(diaSemana => (
                            <DiaSemana
                                key={diaSemana}
                                dia={diaSemana}
                                onChange={event => setDisponibilidade({ ...disponibilidade, [diaSemana]: event.target.checked })}
                                value={disponibilidade[diaSemana]}
                            />
                        ))}
                    </div>
                </div>

                <div className={classes.Group} style={{ gap: '1rem' }}>
                    <Textarea
                        defaultValue={descricao}
                        label="Descrição:"
                        legend="Esse texto está exibido no seu perfil"
                        maxLength="1200"
                        name="descricap"
                        onChange={event => setDescricao(event.target.value)}
                        placeholder="Conte-nos um pouco sobre seu serviço..."
                        rows="6"
                    />

                    {perfil?.imagens ? (
                        <Dropzone
                            defaultValue={perfil.imagens.map(imagem => ({ value: imagem.caminho, name: imagem.nome }))}
                            instructions="Arraste algumas imagens aqui ou clique para selecionar."
                            label="Imagens:"
                            onChange={newImagens => setImagens(newImagens)}
                        />
                    ) : null}
                </div>
            </fieldset>

            <fieldset className={classes.Fieldset}>
                <legend>Informações para contato</legend>

                <div style={{ textAlign: "right", marginBottom: ".5rem" }}>
                    <RiErrorWarningLine style={{ color: "tomato" }} /> Estas informações serão disponibilizadas publicamente.
                </div>

                <div className={classes.Group}>
                    <Input
                        defaultValue={instagram}
                        invalid={!isValid.instagram}
                        label={<span><FaInstagram /> Instagram:</span>}
                        maxLength="160"
                        name="instagram"
                        onChange={event => setInstagram(event.target.value)}
                        placeholder="Ex: portalvegano.insta"
                        replace={/(?![A-Za-z0-9._-])./g}
                    />

                    <Input
                        defaultValue={facebook}
                        invalid={!isValid.facebook}
                        label={<span><FaFacebook /> Facebook:</span>}
                        maxLength="160"
                        name="facebook"
                        onChange={event => setFacebook(event.target.value)}
                        placeholder="Ex: oportalvegano"
                        replace={/(?![A-Za-z0-9._-])./g}
                    />
                </div>

                <div className={classes.Group}>
                    <Input
                        defaultValue={email}
                        invalid={!isValid.email}
                        label="Email para contato:"
                        name="email"
                        onChange={event => setEmail(event.target.value)}
                        placeholder="Ex: contato.portalvegano@gmail.com"
                    />

                    <Input
                        defaultValue={site}
                        invalid={!isValid.site}
                        label="Seu site:"
                        legend="Copie e cole o link para seu site aqui"
                        name="site"
                        onChange={event => setSite(event.target.value)}
                        placeholder="Ex: https://portalvegano.com.br/"
                        replace=" "
                    />
                </div>

                <table className={classes.ContactTable}>
                    <thead>
                        <tr>
                            <th>Celulares</th>
                            <th>Whatsapps</th>
                            <th>Telefones</th>
                        </tr>
                    </thead>

                    <tbody>
                        {[...Array(3)].map((arr, index) => (
                            <tr key={index}>
                                <td>
                                    <Input
                                        defaultValue={celulares[index]}
                                        invalid={!isValid.celulares[index]}
                                        mask="(99) 99999-9999"
                                        name="telefone"
                                        onChange={event => setCelulares(prevCelulares => {
                                            prevCelulares[index] = event.target.value.replace(/\D/g, "");
                                            return [...prevCelulares];
                                        })}
                                        placeholder={`Celular ${index + 1}`}
                                    />
                                </td>

                                <td>
                                    <Input
                                        defaultValue={whatsapps[index]}
                                        invalid={!isValid.whatsapps[index]}
                                        mask="(99) 99999-9999"
                                        name="telefone"
                                        onChange={event => setWhatsapps(prevWhatsapps => {
                                            prevWhatsapps[index] = event.target.value.replace(/\D/g, "");
                                            return [...prevWhatsapps];
                                        })}
                                        placeholder={`Whatsapp ${index + 1}`}
                                    />
                                </td>

                                <td>
                                    <Input
                                        defaultValue={telefones[index]}
                                        invalid={!isValid.telefones[index]}
                                        mask="(99) 9999-9999"
                                        name="fixo"
                                        onChange={event => setTelefones(prevTelefones => {
                                            prevTelefones[index] = event.target.value.replace(/\D/g, "");
                                            return [...prevTelefones];
                                        })}
                                        placeholder={`Telefone ${index + 1}`}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </fieldset>

            <fieldset className={classes.Fieldset}>
                <legend>Endereço</legend>

                <div style={{ textAlign: "right", marginBottom: ".5rem" }}>
                    <RiErrorWarningLine style={{ color: "tomato" }} /> Estas informações serão disponibilizadas publicamente.
                </div>

                <div className={classes.Group}>
                    <Dropdown
                        defaultValue={estado}
                        errorMessage="Selecione um estado antes de prosseguir"
                        invalid={!isValid.estado}
                        label="Estado:"
                        options={[{ id: 0, nome: "Selecione um estado" }, ...estados]}
                        onChange={event => setEstado(parseInt(event.target.value))}
                        required
                    />

                    <Dropdown
                        defaultValue={cidade}
                        disabled={!estado}
                        errorMessage="Selecione uma cidade antes de prosseguir"
                        invalid={!isValid.cidade}
                        label="Cidade:"
                        options={[{ id: 0, nome: "Selecione uma cidade" }, ...cidades]}
                        onChange={event => setCidade(parseInt(event.target.value))}
                        required
                    />
                </div>

                <div className={classes.Group}>
                    <Input
                        defaultValue={cep}
                        inputClassName={classes.Cep}
                        invalid={!isValid.cep}
                        label="CEP:"
                        name="cep"
                        onChange={event => setCep(event.target.value.replace(/\D/g, "") || null)}
                        placeholder="CEP"
                        mask="99999-999"
                        style={{ width: "auto" }}
                    />

                    <Input
                        defaultValue={bairro}
                        label="Bairro:"
                        name="bairro"
                        onChange={event => setBairro(event.target.value || null)}
                        placeholder="Bairro"
                        maxLength="120"
                    />

                    <Input
                        defaultValue={rua}
                        label="Rua:"
                        name="rua"
                        onChange={event => setRua(event.target.value || null)}
                        placeholder="Rua"
                        maxLength="120"
                    />
                </div>

                <div className={classes.Group}>
                    <Input
                        defaultValue={numero}
                        inputClassName={classes.Numero}
                        label="Número:"
                        maxLength="6"
                        name="numero"
                        onChange={event => setNumero(event.target.value || null)}
                        placeholder="Número"
                        replace={/\D/g}
                        style={{ width: "auto" }}
                    />

                    <Input
                        className={classes.Input}
                        defaultValue={complemento}
                        label="Complemento:"
                        maxLength="120"
                        name="complemento"
                        onChange={event => setComplemento(event.target.value || null)}
                        placeholder="Complemento"
                    />
                </div>
            </fieldset>

            <div style={{ textAlign: "center", margin: '2rem 0' }}>
                <Button type="submit" disabled={isLoadingPutRequest}>{isLoadingPutRequest ? "Salvando..." : "Salvar alterações"}</Button>
            </div>
        </form>
    );
};

export default MeuServicoScreen;