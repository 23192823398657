// Actions
import { SET_COORDENADAS } from "../actions/coordenadas";


const initialState = {
    latitude: null,
    longitude: null
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_COORDENADAS:
            return { ...state, latitude: action.latitude, longitude: action.longitude };
        default:
            return state;
    };
};

export default authReducer;