// Dependências
import React from "react";
import { Link } from "react-router-dom";

// Components
import Banner from "../../components/Banner/Banner";
import Categorias from "../../components/Categorias/Categorias";

// Css
import classes from "./CategoriasScreen.module.css";

// Hooks
import useGet from "../../hooks/useGet";


const CategoriasScreen = ({ match }) => {
    const idCategoria = match.params.id;
    const resultCategorias = useGet(`/servicos/categorias${idCategoria ? `/${idCategoria}` : ""}`);

    return (
        <div>
            <Banner />

            <div className={classes.Path}>
                {resultCategorias.categorias ? (
                    [...resultCategorias.path].reverse().map((categoria, index) => (
                        <Link key={categoria.id} to={`/categorias/${categoria.id}`}>
                            {index > 0 ? ' > ' : ""}
                            {categoria.nome}
                        </Link>
                    ))
                ) : null}
            </div>

            <Categorias categorias={resultCategorias.categorias || null} />
        </div>
    );
};

export default CategoriasScreen;