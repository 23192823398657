// Dependências
import React, { memo } from "react";
import { Link } from "react-router-dom";

// Css
import classes from "./Button.module.css";


const Button = ({ children, backgroundColor, color, disabled, onClick, title, to, type, style }) => {
    const ButtonComponent = (
        <button
            className={`${classes.Button} ${disabled ? classes.Disabled : ""}`}
            style={{ backgroundColor, color, ...style }}
            onClick={event => disabled ? null : onClick ? onClick(event) : null}
            title={title}
            type={type || "button"}
        >
            {children}
        </button>
    );

    return to ? <Link to={to}>{ButtonComponent}</Link> : ButtonComponent;
};

export default memo(Button);