// Dependências
import * as yup from 'yup';


export const cep = value => value.length === 8 ? true : false;

export const cnpj = value => {
    if (!value) return false;

    // Verifica se tem 14 dígitos
    if (value.length !== 14) return false;

    // Verifica se todos os caracteres são iguais
    if (/^(.)\1+$/.test(value)) return false;

    // Verifica se tem 0001 na posição correta
    if (value.substring(8, 12) !== "0001") return false;

    const validarDv = (tamanho, charAtValue) => {
        let soma = 0;
        let pos = tamanho - 7;

        for (let i = tamanho; i >= 1; i--) {
            soma += value.substring(0, tamanho).charAt(tamanho - i) * pos--;
            if (pos < 2) pos = 9;
        };

        const resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

        if (resultado !== value.substring(tamanho).charAt(charAtValue)) return false;
    };

    validarDv(cnpj.length - 2, 0);
    validarDv(cnpj.length + 1, 1);

    return true;
};

export const email = value => {
    const schema = yup.string().email().required();
    try {
        schema.validateSync(value);
        return true;
    } catch (error) {
        return false;
    };
};

export const hour = value => /^([0-1][0-9]|[2][0-3]):([0-5][0-9])$/.test(value);

export const link = value => {
    return value?.length > 8 ? true : false;
    /* /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/.test(value); */
};

export const name = value => {
    const schema = yup.string().min(6).max(60).required();
    try {
        schema.validateSync(value);
        return true;
    } catch (error) {
        return false;
    };
};

export const telefone = value => {
    try {
        return value.toString().match(/^\(?(?:[14689][1-9]|2[12478]|3[1234578]|5[1345]|7[134579])\)? ?(?:[2-8]|9[1-9])[0-9]{3}-?[0-9]{4}$/) ? true : false;
    } catch {
        return false;
    };
};