// Dependências
import { createStore, combineReducers, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';

// Reducers
import authReducer from './reducers/auth';
import cadastroReducer from './reducers/cadastro';
import coordenadasReducer from './reducers/coordenadas';
import perfilReducer from './reducers/perfil';
import requestsReducer from './reducers/requests';

const rootReducer = combineReducers({
    auth: authReducer,
    cadastro: cadastroReducer,
    coordenadas: coordenadasReducer,
    perfil: perfilReducer,
    requests: requestsReducer
});

const store = createStore(rootReducer, applyMiddleware(ReduxThunk));

export default store;