// Dependências
import React from "react";
import { AiOutlineFacebook, AiOutlineInstagram, AiOutlinePhone, AiOutlineWhatsApp, AiOutlineMail } from "react-icons/ai";
import { BsMap, BsTextLeft } from "react-icons/bs";
import { FaMoneyBillWave } from "react-icons/fa";
import { MdContactPhone } from "react-icons/md";
import { FiAtSign } from "react-icons/fi";

// Components
import Button from "../../components/UI/Button/Button"
import Header from "../../components/Servico/Header/Header";
import ImagesGalery from "../../components/Servico/ImagesGalery/ImagesGalery";
import ItemsList from "../../components/Servico/ItemsList/ItemsList";
import Map from "../../components/UI/Map/Map";

// Css
import classes from "./ServicoScreen.module.css";

// Helpers
import moneyFormat from "../../helpers/moneyFormat";
import phoneFormat from "../../helpers/phoneFormat";

// Hooks
import useGet from "../../hooks/useGet";


const ServicoScreen = ({ match }) => {
    const idServico = match.params.id;
    const servico = useGet(`/servicos/${idServico}`);

    return servico.nome ? (
        <>
            <Header servico={servico} />

            <div className={classes.Wrapper}>
                <div className={classes.Body}>
                    {servico.imagens.length ? (
                        <section className={classes.Galeria}>
                            <ImagesGalery
                                images={servico.imagens.map(imagem => imagem.caminho)}
                                nome={servico.nome}
                            />
                        </section>
                    ) : null}

                    {servico.descricao ? (
                        <section className={classes.Descricao}>
                            <h2><BsTextLeft /> Descrição</h2>
                            {servico.descricao.split('\n').map((text, index) => <p key={index}>{text}</p>)}
                        </section>
                    ) : null}

                    {servico.servicos.length ? (
                        <section>
                            <h2>Serviços: </h2>
                            <ul>
                                {servico.servicos.map((servico, index) => <li key={index}>{servico}</li>)}
                            </ul>
                        </section>
                    ) : null}

                    {servico.caracteristicas.length ? (
                        <section>
                            <h2>Este estabelecimento oferece:</h2>
                            <ul>
                                {servico.caracteristicas.map(caracteristica => <li key={caracteristica.id}>{caracteristica.nome}</li>)}
                            </ul>
                        </section>
                    ) : null}

                    {Object.values(servico.contatos).filter(contato => contato?.length)?.length ? (
                        <section className={classes.Contato}>
                            <h2><MdContactPhone /> Contato</h2>
                            <ItemsList items={servico.contatos.whatsapps.map(whatsapp => phoneFormat(whatsapp))} icon={<AiOutlineWhatsApp />} />
                            {(servico.contatos.celulares.length || servico.contatos.telefones.length) ? <ItemsList items={[...servico.contatos.celulares.map(celular => phoneFormat(celular)), ...servico.contatos.telefones.map(telefone => phoneFormat(telefone))]} icon={<AiOutlinePhone />} /> : null}
                            {servico.contatos.email ? <div><AiOutlineMail />{servico.contatos.email}</div> : null}
                            {servico.contatos.instagram ? <div><AiOutlineInstagram /> <a href={`https://www.instagram.com/${servico.contatos.instagram}/`} target="_blank" rel="noreferrer">@{servico.contatos.instagram}</a></div> : null}
                            {servico.contatos.facebook ? <div><AiOutlineFacebook /> <a href={`https://www.facebook.com/${servico.contatos.facebook}/`} target="_blank" rel="noreferrer">{servico.contatos.facebook}</a></div> : null}
                            {servico.contatos.site ? <div><FiAtSign /> <a href={servico.contatos.site} target="_blank" rel="noreferrer">{servico.contatos.site}</a></div> : null}
                        </section>
                    ) : null}

                    {servico.endereco.latitude && servico.endereco.longitude ? (
                        <section className={classes.Map}>
                            <h2><BsMap /> Endereço</h2>
                            <div style={{ height: '200px' }}>
                                <Map
                                    latitude={servico.endereco.latitude}
                                    longitude={servico.endereco.longitude}
                                    markers={[{ latitude: servico.endereco.latitude, longitude: servico.endereco.longitude, nome: servico.nome }]}
                                />
                            </div>

                            <div className={classes.MapFooter}>
                                <h3 style={{ margin: 0 }}>{servico.endereco.rua} {servico.endereco.numero}{servico.endereco.rua || servico.endereco.numero ? ", " : ""}{servico.endereco.bairro}</h3>
                                <a href={`http://maps.google.com?q=${servico.endereco.latitude},${servico.endereco.longitude}`} target="_blank" rel="noreferrer"><Button>Obter direções no mapa</Button> </a>
                            </div>
                        </section>
                    ) : null}

                    {(servico.valorMinimo && servico.valorMaximo) ? (
                        <section className={classes.FaixaPreco}>
                            <h2><FaMoneyBillWave /> Faixa de preço</h2>
                            <div style={{ textAlign: 'center' }}>
                                {moneyFormat(servico.valorMinimo)} - {moneyFormat(servico.valorMaximo)}
                            </div>
                        </section>
                    ) : null}
                </div>
            </div>
        </>
    ) : null;
};

export default ServicoScreen;