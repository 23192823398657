export const SAVE_TOKEN = "SAVE_TOKEN";
export const REMOVE_TOKEN = "REMOVE_TOKEN";

// Apaga o token
export const removeToken = () => dispatch => {
    localStorage.removeItem("bearerToken");
    dispatch({ type: REMOVE_TOKEN })
};

// Recebe o access_token da API se o login for válido
export const saveToken = token => dispatch => {
    if (!token) return;
    dispatch({ type: SAVE_TOKEN, token: `Bearer ${token}` });
};