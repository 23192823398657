import Swal from "sweetalert2";

// Junta as opções padrões com as opções customizadas
const mergeObjects = (defaultOptions = {}, options = {}) => {
    const textStyles = `color: var(--text-color); font-family: 'Quicksand', sans-serif;`;

    if (typeof options === 'string') options = { text: options };

    if (options.title) options.title = `<strong style="${textStyles};">${options.title}</strong>`;

    if (options.text || options.html) options.html = `<span style="${textStyles}">${options.text || options.html}</span>`;

    if (options.footer) options.footer = `<span style="${textStyles}">${options.footer}</span>`;


    for (let key in options) defaultOptions[key] = options[key];

    return defaultOptions;
};

export const sweetAlert = options => {
    /* Configurar aqui as opções padrão para todas as mensagens. */
    const defaultOptions = {
        title: "",
        background: "#fff",
        cancelButtonColor: "gray",
        confirmButtonColor: "var(--primary-color)",
        showCancelButton: true,
        confirmButtonText: "Ok",
        cancelButtonText: "Cancelar",
        text: "Houve um erro inesperado. Tente novamente, se o problema persistir por favor contate-nos."
    };

    return Swal.fire(mergeObjects(defaultOptions, options));
};

export const error = options => {
    const defaultOptions = ({
        title: "Ops...",
        icon: "error",
        text: "Alguma coisa deu errado, tente novamente. Se o problema persistir, por favor contate-nos",
        showCancelButton: false,
        confirmButtonText: "OK"
    });

    return sweetAlert(mergeObjects(defaultOptions, options));
};

export const success = options => {
    const defaultOptions = ({
        title: "",
        icon: "success",
        toast: true,
        padding: "0 0.5rem",
        position: 'top-end',
        showCancelButton: false,
        showConfirmButton: false,
        confirmButtonText: "OK"
    });

    return sweetAlert(mergeObjects(defaultOptions, options));
};

export default sweetAlert;