// Dependências
import React from "react";
import { FaMapMarkerAlt } from "react-icons/fa";

// Css
import classes from "./LoadingCardServico.module.css";


const CardServico = () => {
    return (
        <div>
            <div className={classes.ImageWrapper} />

            <div className={classes.Title} />
            <div className={classes.Legend} />
            <div className={classes.LegendWrapper}>
                <FaMapMarkerAlt />
                <div className={classes.Legends}>
                    <div className={classes.Legend} />
                    <div className={classes.Legend} />
                </div>
            </div>
        </div>
    );
};

export default CardServico;