// Dependências
import React from "react";

// Components
import Banner from "../../components/Banner/Banner";
import Categorias from "../../components/Categorias/Categorias";

// Hooks
import useGet from "../../hooks/useGet";


const HomeScreen = () => {
    const resultCategorias = useGet("/servicos/categorias");

    return (
        <div>
            <Banner />
            {<Categorias categorias={resultCategorias.categorias ? resultCategorias.categorias.filter(categoria => categoria.nome !== 'Outros') : null} />}
        </div>
    );
};

export default HomeScreen;