// Dependências
import React from "react";

// Components
import Categoria from "./Categoria/Categoria";
import LoadingCategoria from "./LoadingCategoria/LoadingCategoria";

// Css
import classes from "./Categorias.module.css";


const Categorias = ({ categorias }) => {
    return (
        <section className={classes.Wrapper}>
            <div className={classes.Categorias}>
                {categorias ? (
                    categorias.map(categoria => <Categoria key={categoria.id} categoria={categoria} />)
                ) : (
                    [...Array(11)].map((arr, index) => <LoadingCategoria key={index} />)
                )}
            </div>
        </section>
    );
};

export default Categorias;