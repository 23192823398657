const phoneFormat = value => {
    try {
        if (value.length === 10)
            return value.replace(/(\d{2})(\d{4})(\d{4})/, (regex, arg1, arg2, arg3) => `(${arg1}) ${arg2}-${arg3}`);
        if (value.length === 11)
            return value.replace(/(\d{2})(\d{5})(\d{4})/, (regex, arg1, arg2, arg3) => `(${arg1}) ${arg2}-${arg3}`);
        return value;
    } catch (error) {
        console.error(error);
        return "";
    };
};

export default phoneFormat;