// Dependências
import React from "react";
import { Link } from "react-router-dom";

// Css
import classes from "./Categoria.module.css";


const Categoria = ({ categoria }) => {
    return (
        <Link
            className={classes.Wrapper}
            to={!categoria.hasChildrens ? `/servicos?search=${categoria.nome}&category=${categoria.id}` : `/categorias/${categoria.id}`}
            title={categoria.nome}
        >
            <div className={classes.Image} style={{ backgroundImage: `url("/images/categorias/${categoria.imagem}")` }} />

            <div className={classes.TextWrapper}><span className={classes.Text}>{categoria.nome}</span></div>
        </Link>
    );
};

export default Categoria;