// Dependências
import React from 'react';

// Css
import classes from "./Dropdown.module.css";


const Dropdown = props => {
    return props.options?.length ? (
        <div className={`${classes.Wrapper} ${props.disabled ? classes.Disabled : ""}`}>
            {props.label ? (
                <label className={props.required ? classes.Required : ""} style={{ cursor: 'text' }}>
                    {props.label}
                </label>
            ) : null}

            <select
                className={`${classes.Dropdown} ${props.invalid ? classes.Invalid : ""}`}
                value={props.defaultValue}
                disabled={props.disabled}
                onChange={props.onChange}
                style={{ width: props.width }}
            >
                {props.options.map(item =>
                    <option
                        key={item.id}
                        className={classes.Option}
                        value={item.id}
                    >
                        {item.nome}
                    </option>
                )}
            </select>

            {props.errorMessage && props.invalid ? (
                <legend className={classes.ErrorLegend}>
                    {props.errorMessage}
                </legend>
            ) : null}
        </div>
    ) : null;
};

export default Dropdown