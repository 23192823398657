const moneyFormat = value => {
    try {
        value = parseFloat(value);

        if (value >= 0) {
            value = value.toString().split(".");
            value = value.toString().split(",");

            value[0] = value[0].split(/(?=(?:...)*$)/).join(".");

            if (value.length > 1) {
                if (value[1].length === 1) value = value[0] + "," + value[1] + "0";
                else if (value[1].length === 2) value = value[0] + "," + value[1];
            } else value = value[0];

            return `R$ ${value}`;
        } else {
            value = (value * -1).toString().split(".");
            value = value.toString().split(",");

            value[0] = value[0].split(/(?=(?:...)*$)/).join(".");

            if (value.length > 1) {
                if (value[1].length === 1) value = value[0] + "," + value[1] + "0";
                else if (value[1].length === 2) value = value[0] + "," + value[1];
            } else value = value[0];

            return `R$ ${value}`;
        };
    } catch (error) {
        console.error(error);
        return "";
    };
};

export default moneyFormat;