// Dependências
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

// Components
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './store/index';

// Css
import './index.css';

ReactDOM.render(
    <Provider store={store}>
        <React.StrictMode>
            <BrowserRouter basename="/">
                <App />
            </BrowserRouter>
        </React.StrictMode>
    </Provider>,

    document.getElementById('root')
);


reportWebVitals();
