// Actions
import { SET_PERFIL } from "../actions/perfil";
import { REMOVE_TOKEN } from "../actions/auth";


const initialState = {
    perfil: {},
    hasPerfil: undefined,
};

const perfilReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PERFIL:
            return { ...state, perfil: action.perfil, hasPerfil: action.hasPerfil };
        case REMOVE_TOKEN:
            return { ...state, perfil: {}, hasPerfil: undefined };
        default:
            return state;
    };
};

export default perfilReducer;