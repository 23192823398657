// Dependências
import React from "react";
import { AiFillPhone, AiOutlineCalendar, AiOutlineClockCircle, AiOutlineInstagram } from "react-icons/ai";
import { FaMapMarkerAlt } from "react-icons/fa";

// Css
import classes from "./Header.module.css";

// Helpers
import phoneFormat from "../../../helpers/phoneFormat";


const Header = ({ servico }) => {
    return (
        <header className={classes.Header}>
            <div style={{ marginRight: '3rem' }}>
                <h1>{servico.nome}</h1>

                {servico.contatos.instagram ? (
                    <div className={classes.Instagram}>
                        <a
                            href={`https://www.instagram.com/${servico.contatos.instagram}/`}
                            target="_blank"
                            rel="noreferrer"
                            title={`@${servico.contatos.instagram}`}
                            style={{ color: 'inherit', textDecoration: 'none' }}
                        >
                            <AiOutlineInstagram />
                        </a>
                    </div>
                ) : null}
            </div>

            <div className={classes.Legenda}>
                {servico.contatos.celulares.length || servico.contatos.telefones.length ? (
                    <div style={{ margin: '.3rem 0', color: 'var(--secondary-color)', fontWeight: 'bold' }}>
                        <AiFillPhone />{phoneFormat(servico.contatos.celulares[0] || servico.contatos.telefones[0])}
                    </div>
                ) : null}

                {servico.endereco.bairro || servico.endereco.rua || servico.endereco.numero ? (
                    <div style={{ margin: '.3rem 0' }}>
                        <FaMapMarkerAlt />
                        {servico.endereco.rua} {servico.endereco.numero}{servico.endereco.rua || servico.endereco.numero ? ", " : ""}{servico.endereco.bairro}
                    </div>
                ) : null}

                <ul className={classes.Dias}>
                    <li className={classes.CalendarIcon}><AiOutlineCalendar /></li>
                    {Object.keys(servico.disponibilidade).map(dia => <li key={dia} className={servico.disponibilidade[dia] ? classes.Ativo : ""}>{dia.substring(0, 3).toUpperCase()}</li>)}
                </ul>

                {(servico.horaInicio && servico.horaFim) ? (
                    <span><AiOutlineClockCircle style={{ marginRight: '.3rem' }} /> {servico.horaInicio.substring(0, 5)} - {servico.horaFim.substring(0, 5)}</span>
                ) : null}
            </div>
        </header >
    );
};

export default Header;