// Dependências
import React, { useState } from "react";
import { AiFillCamera } from "react-icons/ai";

// Components
import Modal from "../../UI/Modal/Modal";
import ModalBody from "./ModalBody/ModalBody";

// Css
import classes from "./ImagesGalery.module.css";


const ImagesGalery = ({ images, nome }) => {
    const [showModal, setShowModal] = useState(false);

    return images.length ? (
        <div className={classes.Wrapper}>
            <Modal
                show={showModal}
                onClose={() => setShowModal(false)}
                title={nome}
            >
                <ModalBody images={images} />
            </Modal>

            <div className={classes.ImageWrapper}>
                <img key={0} src={images[0]} alt="" />
            </div>

            {images[1] ? (
                <div className={classes.ImageWrapper}>
                    <img key={1} src={images[1]} alt="" />
                </div>
            ) : null}

            {images.length >= 4 ? (
                <div className={classes.DoubleImageWrapper}>
                    <div className={classes.ImageWrapper}>
                        <img key={2} src={images[2]} alt="" />
                    </div>

                    <div className={classes.ImageWrapper}>
                        <img key={3} src={images[3]} alt="" />
                    </div>
                </div>
            ) : images[2] ? (
                <div className={classes.ImageWrapper}>
                    <img key={2} src={images[2]} alt="" />
                </div>
            ) : null}

            <button
                className={classes.PillowButton}
                onClick={() => setShowModal(true)}
            >
                <AiFillCamera /> Ver mais ({images.length})
            </button>
        </div>
    ) : null;
};

export default ImagesGalery;