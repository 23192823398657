export const SET_CADASTRO = "SET_CADASTRO";

// 
export const setCadastro = (key, value) => {
    return (dispatch, getState) => {
        const newCadastro = { ...getState().cadastro };
        newCadastro[key] = value;

        dispatch({ type: SET_CADASTRO, cadastro: newCadastro });
    };
};