import { CREATE_REQUEST } from "../actions/requests";

const requestsReducer = (state = {}, action) => {
    switch (action.type) {
        case CREATE_REQUEST:
            const requests = { ...state };
            requests[action.key] = action.data;
            return { ...requests };
        default:
            return state;
    };
};

export default requestsReducer;