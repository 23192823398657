// Dependências
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

// Actions
import { saveToken } from "../../store/actions/auth";

// Hooks
import useQuery from "../../hooks/useQuery";
import useAxios from "../../hooks/useAxios";


const LoginScreen = ({ history }) => {
    const dispatch = useDispatch();

    const tempToken = useQuery('token');
    const redirect = useQuery('redirect');
    const tokenReq = useAxios(`GET /servicos/login?token=${tempToken}`, { notSendToken: true });

    // Envia e recebe o token
    useEffect(() => {
        const _tokenReq = async () => {
            if (tempToken) {
                const response = await tokenReq();
                dispatch(saveToken(response.data));
            };

            history.push(redirect ? `/${redirect}` : "/home");
        };

        _tokenReq();
    }, [dispatch, history, redirect, tempToken, tokenReq]);

    return (
        <div style={{ height: '40vh', textAlign: 'center', paddingTop: '2rem' }}>
            Aguarde enquanto validamos suas informações...
        </div>
    );
};

export default LoginScreen;