// Dependências
import React, { useEffect, useState } from "react";
import { RiErrorWarningLine } from "react-icons/ri";
import { useDispatch, useSelector } from 'react-redux';

// Actions
import { setCadastro } from "../../../store/actions/cadastro";

// Css
import classes from "./Cadastro3Screen.module.css";

// Components
import Button from "../../../components/UI/Button/Button";
import Dropdown from "../../../components/UI/Dropdown/Dropdown";
import Input from "../../../components/UI/Input/Input";

// Helpers
import { error as alertError } from "../../../helpers/sweetAlert";
import * as validator from "../../../helpers/validator";

// Hooks
import useGet from "../../../hooks/useGet";


const Cadastro3Screen = ({ history }) => {
    const dispatch = useDispatch();
    const cadastro = useSelector(state => state.cadastro);

    const estados = useGet("/estados", { storage: true });
    const cidades = useGet(cadastro.estado ? `/estados/${cadastro.estado}/cidades` : null, { storage: true });

    const [isValid, setIsValid] = useState({
        estado: true,
        cidade: true,
        cep: true,
        bairro: true,
        rua: true,
        numero: true,
        complemento: true
    });
    const [isCepLoading, setIsCepLoading] = useState(false);

    // Valida se os passos do 1º cadastro foram cumpridos
    useEffect(() => {
        if (!cadastro.nome || !cadastro.categoria) history.push("/cadastro/1");
    }, [cadastro, history]);

    const submitHandler = event => {
        event.preventDefault();
        const copiaIsValid = { ...isValid };

        copiaIsValid.estado = cadastro.estado ? true : false;
        copiaIsValid.cidade = cadastro.cidade ? true : false;
        copiaIsValid.cep = cadastro.cep ? validator.cep(cadastro.cep) : true;
        copiaIsValid.bairro = cadastro.bairro ? validator.name(cadastro.bairro) : true;
        copiaIsValid.rua = cadastro.rua ? validator.name(cadastro.rua) : true;

        setIsValid({ ...copiaIsValid });

        // Se não existir nenhum valor inválido
        if (!Object.values(copiaIsValid).includes(false)) history.push("/cadastro/4");
    };

    const searchCep = async () => {
        if (isCepLoading) return;
        if (!(cadastro.cep ? validator.cep(cadastro.cep) : false)) {
            setIsValid(prevIsValid => ({ ...prevIsValid, cep: false }));
            return;
        };
        setIsValid(prevIsValid => ({ ...prevIsValid, cep: true }))

        setIsCepLoading(true);
        const response = await fetch(`https://viacep.com.br/ws/${cadastro.cep}/json/`, { headers: { 'Accept': 'application/json' } });

        if (response.ok) {
            const result = await response.json();
            dispatch(setCadastro("rua", result.logradouro));
            dispatch(setCadastro("bairro", result.bairro));
        } else alertError({ text: "Não conseguimos encontrar seu endereço." });

        setIsCepLoading(false);
    };

    return (
        <div style={{ padding: "0 .5rem" }}>
            <h1>Cadastre seu Serviço</h1>

            <form onSubmit={submitHandler}>
                <fieldset className={classes.Fieldset}>
                    <legend>Endereço</legend>

                    <div style={{ textAlign: "right", marginBottom: ".5rem" }}>
                        <RiErrorWarningLine style={{ color: "tomato" }} /> Estas informações serão disponibilizadas publicamente.
                    </div>

                    <div className={classes.Row}>
                        <Dropdown
                            defaultValue={cadastro.estado}
                            errorMessage="Selecione um estado antes de prosseguir"
                            invalid={!isValid.estado}
                            label="Estado:"
                            options={[{ id: 0, nome: "Selecione um estado" }, ...estados]}
                            onChange={event => dispatch(setCadastro("estado", parseInt(event.target.value)))}
                            required
                        />

                        <Dropdown
                            defaultValue={cadastro.cidade}
                            disabled={!cadastro.estado}
                            errorMessage="Selecione uma cidade antes de prosseguir"
                            invalid={!isValid.cidade}
                            label="Cidade:"
                            options={[{ id: 0, nome: "Selecione uma cidade" }, ...cidades]}
                            onChange={event => dispatch(setCadastro("cidade", parseInt(event.target.value)))}
                            required
                        />
                    </div>

                    <div className={classes.Row}>
                        <div style={{ display: 'flex' }}>
                            <Input
                                defaultValue={cadastro.cep}
                                inputClassName={classes.Cep}
                                invalid={!isValid.cep}
                                label="CEP:"
                                mask="99999-999"
                                name="cep"
                                onChange={event => dispatch(setCadastro("cep", event.target.value.replace(/\D/g, "")))}
                                placeholder="CEP"
                                style={{ width: "auto" }}
                            />

                            <button type="button" className={classes.SearchCepBtn} onClick={searchCep}>
                                {isCepLoading ? "Buscando..." : "Autocompletar endereço"}
                            </button>
                        </div>

                        <Input
                            invalid={!isValid.bairro}
                            label="Bairro:"
                            name="bairro"
                            onChange={event => dispatch(setCadastro("bairro", event.target.value))}
                            placeholder="Bairro"
                            maxLength="120"
                            value={cadastro.bairro || ""}
                        />

                        <Input
                            invalid={!isValid.rua}
                            label="Rua:"
                            name="rua"
                            onChange={event => dispatch(setCadastro("rua", event.target.value))}
                            placeholder="Rua"
                            maxLength="120"
                            value={cadastro.rua || ""}
                        />
                    </div>

                    <div className={classes.Row}>
                        <Input
                            defaultValue={cadastro.numero}
                            inputClassName={classes.Numero}
                            invalid={!isValid.numero}
                            label="Número:"
                            maxLength="6"
                            name="numero"
                            onChange={event => dispatch(setCadastro("numero", event.target.value))}
                            placeholder="Número"
                            replace={/\D/g}
                            style={{ width: "auto" }}
                        />

                        <Input
                            className={classes.Input}
                            defaultValue={cadastro.complemento}
                            invalid={!isValid.complemento}
                            label="Complemento:"
                            maxLength="120"
                            name="complemento"
                            onChange={event => dispatch(setCadastro("complemento", event.target.value))}
                            placeholder="Complemento"
                        />
                    </div>
                </fieldset>


                <div style={{ display: "flex", justifyContent: "space-between", marginTop: '4rem' }}>
                    <Button backgroundColor="hsl(255, 0%, 30%)" to="/cadastro/2">Voltar</Button>

                    <Button type="submit">Continuar</Button>
                </div>
            </form>
        </div>
    );
};

export default Cadastro3Screen;