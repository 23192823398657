// Dependências
import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { IoMdOptions } from "react-icons/io";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

// Css
import classes from "./Menu.module.css";

// Components
import Toggle from '../../UI/Toggle/Toggle';

const Menu = ({ categorias, search, onMyLocation, setOnMyLocation }) => {
    const coordinates = useSelector(state => state.coordenadas);

    const [showMenu, setShowMenu] = useState(false);

    return (
        <>
            <div className={classes.Button} onClick={() => setShowMenu(prevShowMenu => !prevShowMenu)}>
                <IoMdOptions /> Filtrar
            </div>

            <div className={classes.Wrapper} style={{ display: showMenu ? 'block' : 'none' }}>
                <AiOutlineClose className={classes.CloseButton} onClick={() => setShowMenu(false)} />

                {coordinates.latitude && coordinates.longitude ? (
                    <div className={classes.MinhaRegiaoButton} style={{ whiteSpace: 'nowrap' }}>
                        <label htmlFor="toggle-na-minha-regiao">Na minha região</label>
                        {' '}
                        <Toggle
                            id="toggle-na-minha-regiao"
                            defaultChecked={onMyLocation}
                            onChange={event => setOnMyLocation(event.target.checked)}
                        />
                    </div>
                ) : null}

                {categorias.length ? (
                    <div className={classes.Categorias}>
                        <strong>Categorias</strong>
                        <ul>
                            {categorias.map(categoria => (
                                <li key={categoria.id}>
                                    <Link
                                        to={`/servicos?search=${search}&category=${categoria.id}`}
                                        className={classes.Link}
                                        onClick={() => setShowMenu(false)}
                                    >
                                        {categoria.nome}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                ) : null}
            </div>
        </>
    );
};

export default Menu;