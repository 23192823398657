// Dependências
import React from "react";
import { BiHome, BiLogOut, BiStore, BiUser } from "react-icons/bi";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CSSTransition } from "react-transition-group";

// Actions
import { removeToken } from "../../../store/actions/auth";

// Css
import classes from "./SideDrawer.module.css";

const REDIRECT_URL = process.env.REACT_APP_REDIRECT_URL;

const SideDrawer = ({ display, onClose }) => {
    const dispatch = useDispatch();
    const hasPerfil = useSelector(state => state.perfil.hasPerfil);
    const token = useSelector(state => state.auth.token);

    return (
        <>
            <div
                className={classes.Background}
                style={{ display: display ? 'block' : 'none' }}
                onClick={onClose}
            />

            <CSSTransition
                in={display}
                unmountOnExit
                timeout={{ enter: 0, exit: 300 }}
                classNames={{
                    enterDone: classes.EnterDone,
                    exit: classes.Exit
                }}
            >
                <nav className={classes.Wrapper}>
                    <ul onClick={onClose}>
                        <li>
                            <NavLink to="/home">
                                <BiHome /> Início
                            </NavLink>
                        </li>

                        {hasPerfil ? (
                            <li>
                                <NavLink to="/meu-perfil">
                                    <BiUser /> Meu Perfil
                                </NavLink>
                            </li>
                        ) : !token ? (
                            <li>
                                <a href={`https://portalvegano.com.br/login?redirecionar=${btoa(REDIRECT_URL)}`}> <BiUser /> Entrar</a>
                            </li>
                        ) : (
                            <li>
                                <NavLink to="/cadastro"> <BiUser /> Cadastrar-se</NavLink>
                            </li>
                        )}

                        <li>
                            <a href="https://portalvegano.com.br/" target="_blank" rel="noreferrer"><BiStore /> Loja</a>
                        </li>

                        {hasPerfil ? (
                            <li>
                                <button onClick={() => dispatch(removeToken())}>
                                    <BiLogOut />  Sair
                                </button>
                            </li>
                        ) : null}
                    </ul>
                </nav>
            </CSSTransition>
        </>
    );
};

export default SideDrawer;