// Dependências
import React from "react";
import { AiFillMail, AiOutlineWhatsApp } from "react-icons/ai";
import { IoTimeSharp } from "react-icons/io5";
import { useSelector } from "react-redux";
import { NavLink } from 'react-router-dom';

// Css
import classes from "./Footer.module.css";

const REDIRECT_URL = process.env.REACT_APP_REDIRECT_URL;

const Footer = () => {
    const hasPerfil = useSelector(state => state.perfil.hasPerfil);
    const token = useSelector(state => state.auth.token);

    const link = "https://portalvegano.com.br";

    return (
        <footer className={classes.Footer}>
            <section className={classes.Wrapper}>
                <div className={classes.LogoWrapper}>
                    <img
                        className={classes.Logo}
                        src="/images/logo.png"
                        alt="Portal Vegano"
                    />
                </div>

                <div className={classes.Col}>
                    <h1>Institucional</h1>
                    <hr className={classes.Divisor} />
                    <a href={`${link}/paginas/quem-somos-2`}>Quem somos</a>
                    <a href={`${link}/blog`}>Blog</a>
                    <a href={`${link}/paginas/termo-de-uso-geral`}>Termos de Uso - Geral</a>
                    <a href={`${link}/paginas/politica-de-privacidade`}>Política de Privacidade</a>
                </div>

                <div className={classes.Col}>
                    <h1>Ajuda</h1>
                    <hr className={classes.Divisor} />
                    {!token ? (
                        <a href={`https://portalvegano.com.br/login?redirecionar=${btoa(`${REDIRECT_URL}?redirect=cadastro`)}`}>Quero anunciar meus serviços especializados</a>
                    ) : (
                        <NavLink to={hasPerfil ? '/meu-perfil' : '/cadastro'}>Quero anunciar meus serviços especializados</NavLink>
                    )}
                </div>

                <div className={classes.Col}>
                    <h1>Atendimento</h1>
                    <hr className={classes.Divisor} />
                    <span><AiOutlineWhatsApp className={classes.Icon} /> (45) 9 9152-6684</span>
                    <span><AiFillMail className={classes.Icon} /> contato.portalvegano@gmail.com</span>
                    <span><IoTimeSharp className={classes.Icon} /> Seg à Sex: 08h às 12h - 14h às 18h</span>

                    <a href="https://www.facebook.com/oportalvegano/" className={classes.LogoSocialMedia}><img src="/images/logos/facebook.png" alt="Facebook" /></a>
                    <a href="https://www.instagram.com/portalvegano.insta/" className={classes.LogoSocialMedia}><img src="/images/logos/instagram.png" alt="Instagram" /></a>

                    <span className={classes.Small}>CNPJ: 30.952.846/0001-92</span>
                    <span className={classes.Small}>Portal Vegano Portal de Internet LTDA</span>
                </div>
            </section>
        </footer >
    );
};

export default Footer;