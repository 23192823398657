// Dependências
import React, { useCallback, useEffect, useMemo, useState } from "react";
import InputMask from 'react-input-mask';

// Css
import classes from "./Input.module.css";

// Helpers
import randomString from "../../../helpers/randomString";


const Input = props => {
    const [inputValue, setInputValue] = useState(props.defaultValue || props.value);

    const id = useMemo(() => randomString(), []);

    // Ao mudar value, seta o inputValue
    useEffect(() => {
        setInputValue(props.value);
    }, [props.value]);

    const onChangeHandler = useCallback(event => {
        if (props.replace) {
            let replacedValue = event.target.value.replace(props.replace, '');

            if (replacedValue !== event.target.value) {
                event.target.value = replacedValue;
                return;
            };
        };

        if (props[event._reactName]) props[event._reactName](event);
    }, [props]);


    return (
        <div className={`${classes.Wrapper} ${props.className || ''}`} style={props.style}>
            {props.label ? (
                <label className={props.required ? classes.Required : ""} htmlFor={props.id || id} style={{ cursor: 'text' }}>
                    {props.label}
                </label>
            ) : null}

            <InputMask
                id={props.id || id}
                className={`${classes.Input} ${props.invalid ? classes.Invalid : ""} ${props.inputClassName || ""}`}
                defaultValue={props.defaultValue}
                mask={props.mask}
                maskChar={props.maskChar}
                maxLength={props.maxLength}
                name={props.name}
                onBlur={onChangeHandler}
                onChange={onChangeHandler}
                onKeyDown={event => (event.key === "Enter" && !props.enterHandler) ? event.target.blur() : event.key === "Enter" ? props.enterHandler(event) : null}
                placeholder={props.placeholder || props.label}
                type={props.type || "text"}
                value={props.value !== undefined ? inputValue : undefined}
                style={props.inputStyle}
            />

            {props.errorMessage && props.invalid ? (
                <legend className={classes.ErrorLegend}>
                    {props.errorMessage}
                </legend>
            ) : props.legend ? (
                <legend className={classes.Legend}>
                    {props.legend}
                </legend>
            ) : null}
        </div>
    );
};

export default Input;