// Dependências
import React, { useMemo } from "react";

// Css
import classes from './Textarea.module.css';

// Helpers
import randomString from "../../../helpers/randomString";


const Textarea = props => {
    const id = useMemo(() => randomString(), []);

    return (
        <div className={classes.Wrapper}>
            {props.label ? (
                <label htmlFor={id || props.id} className={classes.Label}>
                    {props.label}
                </label>
            ) : null}

            <textarea
                autoFocus={props.autoFocus}
                className={classes.Textarea}
                defaultValue={props.defaultValue}
                id={id || props.id}
                maxLength={props.maxLength}
                placeholder={props.placeholder || props.label}
                rows={props.rows || "2"}
                size="90%"
                style={{ "--rows-quantity": props.rows || "2" }}
                onBlur={props.onBlur}
                onChange={props.onChange}
            />

            {props.errorMessage && props.invalid ? (
                <legend className={classes.ErrorLegend}>
                    {props.errorMessage}
                </legend>
            ) : props.legend ? (
                <legend className={classes.Legend}>
                    {props.legend}
                </legend>
            ) : null}
        </div>
    );
};

export default Textarea;