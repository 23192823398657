// Dependências
import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';

// Css
import classes from "./Map.module.css";


const Map = ({ height, latitude, longitude, markers, width }) => {
    return (
        <div className={classes.Wrapper} style={{ height: height || '100%', width: width || '100%' }}>
            <MapContainer
                center={[latitude, longitude]}
                zoom={16}
            >
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />

                {markers?.map((marker, index) => (
                    <Marker key={index} position={[marker.latitude, marker.longitude]}>
                        <Popup> {marker.nome} </Popup>
                    </Marker>
                ))}
            </MapContainer>
        </div>
    );
};

export default Map;